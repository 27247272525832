import {useTranslation} from "react-i18next";
import React from "react";

export const KavAppLink = () => {
    const {i18n} = useTranslation();
    const language = i18n.language
    return <a href={`https://admin.kavapp.com/#/registration?language=${language}`} target="_blank"
              title={'Kavapp'}>Kavapp.com</a>
}

export const SellerLink = () => {
    const {i18n} = useTranslation()
    return <a
        href={`https://kavapp.com/${i18n.language}/portfolio/sale`}
        target="_blank"
        title={'Kavapp Seller'}
    >Kavapp Seller</a>
}

export const UserAgreement = () => {
    const {t} = useTranslation()
    return <a href={'https://admin.kavapp.com/files/user_agreement.pdf'} target="_blank"
              title={'Посилання'}>{t('faq.link')}</a>
}

export const PrivacyPolicy = () => {
    const {t} = useTranslation()
    return <a href={'https://kavapp.com/pdf/confidentiality.pdf'} target="_blank"
              title={'Посилання'}>{t('faq.link')}</a>
}

export const Email = () => {
    return <a href={'mailto:info@kavapp.com'} target="_blank" title={'info@kavapp.com'}>info@kavapp.com</a>
}