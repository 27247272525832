import {useTranslation} from "react-i18next";
import {useMemo} from "react";

import SliderForPortfolio from "../../../pageComponents/SliderForPortfolio/SliderForPortfolio";
import ButtonToPrivatAccount from "../../../pageComponents/Buttons/ButtonToPrivatAccount/ButtonToPrivatAccount";

import "./portfolio-admin.scss"

import slide1en from "../../../../img/image/kavapp/01-en.png"
import slide1ua from "../../../../img/image/kavapp/01-ua.png"
import slide2en from "../../../../img/image/kavapp/02-en.png"
import slide2ua from "../../../../img/image/kavapp/02-ua.png"
import slide3en from "../../../../img/image/kavapp/03-en.png"
import slide3ua from "../../../../img/image/kavapp/03-ua.png"
import slide4en from "../../../../img/image/kavapp/04-en.png"
import slide4ua from "../../../../img/image/kavapp/04-ua.png"
import slide5en from "../../../../img/image/kavapp/05-en.png"
import slide5ua from "../../../../img/image/kavapp/05-ua.png"
import slide6en from "../../../../img/image/kavapp/06-en.png"
import slide6ua from "../../../../img/image/kavapp/06-ua.png"
import slide7en from "../../../../img/image/kavapp/07-en.png"
import slide7ua from "../../../../img/image/kavapp/07-ua.png"
import slide8en from "../../../../img/image/kavapp/08-en.png"
import slide8ua from "../../../../img/image/kavapp/08-ua.png"
import slide9en from "../../../../img/image/kavapp/09-en.png"
import slide9ua from "../../../../img/image/kavapp/09-ua.png"
import slide10en from "../../../../img/image/kavapp/10-en.png"
import slide10ua from "../../../../img/image/kavapp/10-ua.png"

const slidersList = {
    en: [slide1en, slide2en, slide3en, slide4en, slide5en, slide6en, slide7en, slide8en, slide9en, slide10en],
    ua: [slide1ua, slide2ua, slide3ua, slide4ua, slide5ua, slide6ua, slide7ua, slide8ua, slide9ua, slide10ua],
    text: ["first_slide", "second_slide", "three_slide", "fourth_slide", "fifth_slide",
        "sixth_slide", "seventh_slide", "eighth_slide", "ninth_slide", "tenth_slide"]
}

function PortfolioAdmin() {
    const {t, i18n} = useTranslation()

    const slides = useMemo(() => {
        const lng = (i18n.language === 'en' || i18n.language === 'es') ? 'en' : 'ua'

        return Array.isArray(slidersList[lng]) ? slidersList[lng].map((item, index) => {
            return {
                img: (<img src={item} className="computer" alt="Kavapp admin panel"/>),
                text: (
                    <p className={'description'}>
                        {t(`pages.portfolio.portfolio_admin.description_slides.${slidersList.text[index]}`)}
                    </p>)
            }
        }) : []
    }, [i18n.language])

    return (
        <div className="admin">
            <h1 className="page-title">Kavapp Admin</h1>
            <h3 className="subtitle">{t("components.programForAdmin")}</h3>
            <p className="description-first">{t("pages.portfolio.portfolio_admin.description")}</p>
            <p className="description-second">{t("pages.portfolio.portfolio_admin.post_description")}</p>
            <div className="personal-office-block">
                <ButtonToPrivatAccount className="personal-office"/>
            </div>
            <SliderForPortfolio slides={slides}/>
        </div>
    )
}

export default PortfolioAdmin
