import React from 'react';
import FaqList from "./FaqList";
import './Faq.scss'

const Faq = () => {
    return (
        <div className="faq_page">
            <FaqList/>
        </div>
    );
};

export default Faq;