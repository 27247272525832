import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

import logoLight from "../../../../../img/logo-light.svg";
import logoNight from "../../../../../img/logo-night.svg";
import {GlobalContext} from "../../../../../app/providers/ContextProvider";
import './Logo.scss'

const Logo = () => {
    const {globalState: {theme}} = useContext(GlobalContext)
    const {i18n} = useTranslation()

    return (
        <div className="logo">
            <Link to={`/${i18n.language}/about_me`}>
                <img className="logo-image" src={theme === "light" ? logoLight : logoNight}
                     alt="logo"/>
            </Link>
        </div>
    );
};

export default Logo;