import React from "react";

export const textList = [
    {
        name: '1',
        children: [
            {
                name: '1_1',
                children: [
                    {
                        text: '1_1t',
                        components: ["kavAppLink", "sellerLink"]
                    },
                ]
            },
            {
                name: '1_2',
                children: [
                    {text: '1_2t1'},
                    {text: '1_2t2'},
                    {text: '1_2t3'},
                    {text: '1_2t4'},
                ]
            },
            {
                name: '1_3',
                children: [{text: '1_3t'}]
            },
            {
                name: '1_4',
                children: [{text: '1_4t'}]
            },
            {
                name: '1_5',
                children: [{text: '1_5t'}]
            },
            {
                name: '1_6',
                children: [{text: '1_6t'}]
            }
        ]
    },
    {
        name: '2',
        children: [
            {
                name: '2_1',
                children: [{text: '2_1t'},]
            },
            {
                name: '2_2',
                children: [{text: '2_2t'}]
            },
            {
                name: '2_3',
                children: [
                    {text: '2_3t1'},
                    {text: '2_3t2'},
                    {text: '2_3t3'}
                ]
            },
            {
                name: '2_4',
                children: [
                    {
                        text: '2_4t1',
                        components: ["userAgreement"]
                    },
                    {
                        text: '2_4t2',
                        components: ["privacyPolicy"]
                    },
                ]
            },
            {
                name: '2_5',
                children: [
                    {
                        text: '2_5t1',
                        components: ["email"]
                    },
                    {text: '2_5t2'},
                ]
            }
        ]
    },
    {
        name: '3',
        children: [
            {
                name: '3_1',
                children: [{text: '3_1t'},]
            },
            {
                name: '3_2',
                children: [
                    {text: '3_2t1'},
                    {text: '3_2t2'},
                    {text: '3_2t3'},
                    {text: '3_2t4'}
                ]
            },
            {
                name: '3_3',
                children: [{text: '3_3t'}]
            },
            {
                name: '3_4',
                children: [{text: '3_4t'},]
            },
        ]
    },
    {
        type: "prro",
        name: '4',
        children: [
            {
                name: '4_1',
                children: [{text: '4_1t'},]
            },
        ],
    }
]
