import {useContext} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

import MainBanner from "./Banner/Banner";

import "./about_us.scss"

import {ReactComponent as LowPrice} from "../../../img/icons/low_price.svg"
import {ReactComponent as Pppo} from "../../../img/icons/pPPO.svg"
import {ReactComponent as TechCard} from "../../../img/icons/tech_cards.svg"
import {ReactComponent as Wage} from "../../../img/icons/wage.svg"
import {ReactComponent as Control} from "../../../img/icons/control.svg"
import {ReactComponent as Rediscount} from "../../../img/icons/rediscount.svg"
import {ReactComponent as SaleFromWarehouse} from "../../../img/icons/sele_from_warehouse.svg"
import {ReactComponent as Charts} from "../../../img/icons/charts.svg"
import {ReactComponent as Messenger} from "../../../img/icons/messenger.svg"
import {ReactComponent as Cooking} from "../../../img/icons/cooking.svg"
import {ReactComponent as Device} from "../../../img/icons/device.svg"
import {GlobalContext} from "../../../app/providers/ContextProvider";


function AboutUs() {
    const {t, i18n} = useTranslation()
    const {globalState: {windowIsSmall, country}} = useContext(GlobalContext)

    return (
        <div className="about_us">
            <MainBanner/>
            <div className="main-content">
                <div className="title-wrapper">
                    <h1 className="page-title">{t('words.about_us')}</h1>
                    <h2 className="subtitle">{t("pages.about_us.subtitle")}</h2>
                </div>
                <p className="description">{t("pages.about_us.description_first")}</p>
                <p className="description">{t("pages.about_us.description_second")}</p>
                <p className="hidden-text">программа постер для кофейни. crm для кофейни. программа учета для кофейни.
                    pos система для кафе. система учета для кофейни. учет кофейни. программа для кофейни. система учета
                    для кафе.
                    как вести учет в кофейне. pos система для кофейни. айко программа для ресторанов. учет кафе в excel.
                    айко программа для ресторанов отзывы. программа iiko. программа для ресторанов. програма для кафе.
                    программа для кафе скачать бесплатно. iiko программа для ресторанов. постер программа.
                    программа для кафе на планшет. программное обеспечение для кофейни. автоматизация ресторана.
                    автоматизация кофейни.
                    купить кипер для кафе. программа для автоматизации кафе. кипер программа. автоматизація кафе.
                    iiko программа. pos терминал для кафе. программа для ресторана украина. кипер для кафе. программа
                    для ресторана.
                    учет в кофейне. постер программа. бесплатные программы для общепита. программы для кофейни.
                    poster программа для кафе отзывы. автоматизация кафе. бесплатные программы для общепита.
                    программа для кафе бесплатно. как вести учет в кофейне. программа автоматизации кафе. програма для
                    кофейни.
                    приложение для кофейни. учетная программа для кафе. poster программа для кафе. айко программа для
                    ресторанов.
                    программа учета для кафе. программа для кафе. програма обліку для кафе. программное обеспечение для
                    кафе.
                    программа кипер для кафе. программа для учета кофейни. программы для кофейни. программа учета кафе.
                    программа для учета в кофейне. программа постер для кофейни. программа для ресторана. автоматизація
                    бізнесу.
                    автоматизация бизнеса. Poster. постер. кавап. каваап. kavapp. kavaap. iiko. iko. іко програма.
                    ико програма. иико. ііко. прро. рро. фискализация. програмный рро. рограмний рро.

                    програма для ресторана Україна. програма для ресторану Україна. кіпер для кафе. програма для
                    ресторана.
                    програма для ресторану. облік в кав'ярні. постер програма. безкоштовні програм для кав'ярні.
                    безкоштовні програм для кафе. програми для кав'ярні. poster програма для кафе відгуки. автоматизація
                    кафе.
                    автоматизаця кав'ярні. програма для кафе безкоштовно. як вести облік в кав'ярні. програма
                    автоматизації кафе.
                    програма для кав'ярні. додаток для кав'ярні. облікова програма для кафе. poster програма для кафе.
                    айко прогрмма для ресторанів. програма обліку для кафе. програма для кафе. програмне забезпечення
                    для кафе.
                    програма кіпер для кафе. програма для обліку кав'ярні. програми для кав'ярні. програм обліку кафе.
                    програма для обліку в кав'ярні. програма постер для кав'ярні. програма для ресторану.
                    програма для ресторана. автоматизація бізнесу. Poster. постер. кавап. каваап. kavapp. kavaap. iiko.
                    iko. іко програма. ііко. прро. рро. фіскалізація. програмний рро.

                    crm for caffeine. accounting program for coffee shop. pos system for cafes. accounting system for
                    coffee shop.
                    caffeine account. program for caffeine. accounting system for cafes. how to keep records in a coffee
                    shop.
                    pos system for caffeine. aiko program for restaurants. cafe accounting in excel. Aiko program for
                    restaurants reviews.
                    program for restaurants. program for cafes. program for cafe download for free. poster program.
                    program for a cafe on a tablet. coffee shop software. restaurant automation. coffee shop automation.
                    buy a keeper for a cafe. program for cafe automation. Kiper program. cafe automation. pos terminal
                    for cafe.
                    keeper for the cafe. program for a restaurant. poster program. free public relations programs.
                    programs for caffeine. cafe automation. free public relations programs. the program for the cafe is
                    free.
                    how to keep records in a coffee shop. cafe automation program. program for caffeine. caffeine app.
                    account program for cafes. accounting software for cafes. program for cafes. accounting program for
                    cafes.
                    software for cafes. keeper program for cafes. a program for accounting for caffeine. programs for
                    caffeine.
                    cafe accounting program. a program for accounting in a coffee shop. the poster program for the cafe.
                    program for a restaurant. business automation. free automation app.

                    crm para cafeina. programa de contabilidad para cafeterias. sistema pos para cafeterías.
                    sistema contable para cafeteria. cuenta de cafeina. programa de cafeina. sistema de contabilidad
                    para cafeterías.
                    Cómo llevar registros en una cafetería. sistema pos para cafeina. programa aiko para restaurantes.
                    cafe contabilidad en excel. Programa Aiko para reseñas de restaurantes. programa para restaurantes.
                    programa para cafeterías. programa para descargar cafe gratis. programa de carteles.
                    programa para un cafe en una tablet. software de cafeteria. automatización de restaurantes.
                    automatizacion de cafeteria. comprar un guardián para un café. programa para automatizar cafeterías.
                    programa kiper. cafe automatizacion. terminal pos para cafetería. guardián de la cafetería.
                    programa para un restaurante. programa de carteles. programas gratuitos de relaciones publicas.
                    programas para cafeina. cafe automatizacion. programas gratuitos de relaciones publicas.
                    el programa para el cafe es gratis. Cómo llevar registros en una cafetería. programa de
                    automatizacion de cafe.
                    programa de cafeina. aplicación de cafeína. programa de cuentas para cafeterías. software de
                    contabilidad para cafeterías.
                    programa para cafeterías. programa de contabilidad para cafeterías. software para cafeterías.
                    programa guardián para cafeterías. un programa para contabilizar la cafeína. programas para cafeina.
                    programa de contabilidad cafeteria. un programa para la contabilidad en una cafeteria.
                    el programa de carteles para el cafe. programa para un restaurante. automatización de negocios.
                    aplicación de automatización gratuita.
                </p>


                <ul className="advantages-list">
                    <li className="advantages-list-item">
                        <LowPrice/>
                        <p>{!windowIsSmall ? t("pages.about_us.advantages.low_price") : t("pages.about_us.advantages.low_price_mini")}</p>
                    </li>
                    {
                        country === 'UA' &&
                        <li className="advantages-list-item">
                            <Pppo/>
                            <p>{!windowIsSmall ? t("pages.about_us.advantages.pppo") : t("pages.about_us.advantages.pppo_mini")}</p>
                        </li>
                    }
                    <li className="advantages-list-item">
                        <TechCard/>
                        <p>{!windowIsSmall ? t("pages.about_us.advantages.tech_card") : t("pages.about_us.advantages.tech_card_mini")}</p>
                    </li>
                    <li className="advantages-list-item">
                        <Wage/>
                        <p>{!windowIsSmall ? t("pages.about_us.advantages.wage") : t("pages.about_us.advantages.wage_mini")}</p>
                    </li>
                    <li className="advantages-list-item">
                        <Control/>
                        <p>{!windowIsSmall ? t("pages.about_us.advantages.control") : t("pages.about_us.advantages.control_mini")}</p>
                    </li>
                    <li className="advantages-list-item">
                        <Rediscount/>
                        <p>{!windowIsSmall ? t("pages.about_us.advantages.rediscount") : t("pages.about_us.advantages.rediscount_mini")}</p>
                    </li>
                    <li className="advantages-list-item">
                        <SaleFromWarehouse/>
                        <p>{!windowIsSmall ? t("pages.about_us.advantages.sale_from_warehouse") : t("pages.about_us.advantages.sale_from_warehouse_mini")}</p>
                    </li>
                    <li className="advantages-list-item">
                        <Charts/>
                        <p>{!windowIsSmall ? t("pages.about_us.advantages.charts") : t("pages.about_us.advantages.charts_mini")}</p>
                    </li>
                    <li className="advantages-list-item">
                        <Messenger/>
                        <p>{!windowIsSmall ? t("pages.about_us.advantages.messenger") : t("pages.about_us.advantages.messenger_mini")}</p>
                    </li>
                    <li className="advantages-list-item">
                        <Cooking/>
                        <p>{!windowIsSmall ? t("pages.about_us.advantages.cooking") : t("pages.about_us.advantages.cooking_mini")}</p>
                    </li>
                    <li className="advantages-list-item">
                        <Device/>
                        <p>{!windowIsSmall ? t("pages.about_us.advantages.device") : t("pages.about_us.advantages.device_mini")}</p>
                    </li>
                </ul>
                <div className="categories">
                    <span>{t("pages.about_us.categories.title")}</span>
                    <p>
                        <Link to={`/${i18n.language}/portfolio/admin`}
                              className="link">{t("pages.about_us.categories.admin_title")}</Link>
                        {t("pages.about_us.categories.admin_description")}
                    </p>
                    <p>
                        <Link to={`/${i18n.language}/portfolio/sale`}
                              className="link">{t("pages.about_us.categories.seller_title")}</Link>
                        {t("pages.about_us.categories.seller_description")}
                    </p>
                    <p>
                        <Link to={`/${i18n.language}/portfolio/delivery`}
                              className="link">{t("pages.about_us.categories.delivery_title")}</Link>
                        {t("pages.about_us.categories.delivery_description")}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutUs