import ButtonToPrivatAccount from "../../../pageComponents/Buttons/ButtonToPrivatAccount/ButtonToPrivatAccount";
import {useTranslation} from "react-i18next";

import device_ua from "../../../../img/image/img-device_ua.png"
import device_en from "../../../../img/image/img-device_en.png"
import "./Banner.scss"
import {useMemo} from "react";

const images = {
    en: device_en,
    ua: device_ua,
}

function MainBanner() {
    const {t, i18n} = useTranslation()

    const slide = useMemo(() => {
        const lng = (i18n.language === 'en' || i18n.language === 'es') ? 'en' : 'ua'

        return images[lng]
    }, [i18n.language])

    return (
        <div className="banner-page">
            <div className="banner">
                <div className="bg-banner"/>
                <div className="banner-wrapper">
                    <div className="left-block">
                        <h1 className="title">Kavapp - </h1>
                        <h2 className="description-title">{t("pages.about_us.banner")}</h2>
                        <ButtonToPrivatAccount className={'private-account-button'}/>
                    </div>
                    <div className="right-block">
                        <img src={slide} alt="device"/>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default MainBanner;