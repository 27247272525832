const text = {
    search: "Buscar",
    title: "FAQ (Preguntas y Respuestas)",
    link: "enlace"
}

const list1 = {
    "1": "Conexión, equipo necesario, inicio de trabajo.",
    "1_1": "¿Cómo conectar el sistema Kavapp?",
    "1_1t": "Para trabajar con nuestro sistema, solo necesita registrarse en nuestro sitio web <0>Kavapp.com</0> y descargar la aplicación <1>Kavapp Seller</1> en su dispositivo de trabajo. Puede realizar todas las configuraciones del sistema por su cuenta o con la ayuda de nuestro soporte técnico.",

    "1_2": "¿Con qué equipo funciona Kavapp?",
    "1_2t1": "Kavapp Admin funciona en formato de sitio web y puede abrirse desde cualquier navegador en cualquier dispositivo.",
    "1_2t2": "Kavapp Seller funciona como una aplicación móvil en los sistemas operativos Windows (10+, 64 bits), Android (mínimo 6, recomendado 9+) e iOS (mínimo 12, recomendado 15+).",
    "1_2t3": "Kavapp Delivery funciona como una aplicación móvil en Android (mínimo 6, recomendado 9+) e iOS (mínimo 12, recomendado 15+).",
    "1_2t4": "La aplicación Kavapp Seller admite la conexión de impresoras de recibos (Bluetooth, USB, Wi-Fi) y escáneres de códigos de barras (Bluetooth, USB).",

    "1_3": "¿Está el sistema listo para usar después del registro?",
    "1_3t": "Sí, inmediatamente después del registro, obtiene una versión completa del sistema con un período de prueba de 2 semanas gratis en cualquier tarifa. El programa incluye productos estándar y recetas técnicas que puede editar y configurar durante el período de prueba y después de su finalización. Todas las configuraciones que realice durante el período de prueba se guardarán después de su finalización.",
    "1_4": "¿Los empleados de Kavapp configuran nuestro sistema o instalan el equipo en la cafetería?",
    "1_4t": "No, trabajamos solo en línea y no conectamos equipos en su cafetería. Sin embargo, si necesita ayuda para conectar o configurar el sistema, puede contactar a nuestro soporte técnico; estaremos encantados de ayudarle.",
    "1_5": "¿Se necesita Internet para trabajar con el programa?",
    "1_5t": "Se requiere Internet para abrir y cerrar el turno de trabajo en la aplicación Kavapp Seller. Si el turno ya ha comenzado, la aplicación puede funcionar sin conexión (sin embargo, la información en el gabinete personal no se sincronizará y se sincronizará cuando el dispositivo se conecte a Internet).",
    "1_6": "¿Es posible comprar equipos en Kavapp?",
    "1_6t": "Actualmente no vendemos ningún equipo, pero puede obtener recomendaciones sobre ciertos equipos de nuestro soporte técnico.",
}

const list2 = {
    "2": "Sobre la empresa Kavapp.",
    "2_1": "¿Cuánto tiempo lleva funcionando el servicio Kavapp?",
    "2_1t": "Nuestro sistema ha estado funcionando desde 2019. Trabajamos constantemente en mejorar y expandir nuestro servicio y no planeamos detenernos en el futuro.",
    "2_2": "¿Con qué frecuencia se actualiza y mejora el sistema?",
    "2_2t": "Las actualizaciones globales de las aplicaciones se lanzan mensualmente, y podemos lanzar pequeñas correcciones con mayor frecuencia. Todo esto es necesario para garantizar el funcionamiento estable del sistema y mejorar su funcionalidad. Nos esforzamos por escuchar a nuestros clientes para ofrecer servicios lo más cómodos y efectivos posible.",

    "2_3": "¿Cuáles son las principales ventajas de Kavapp sobre otros sistemas POS para cafeterías?",
    "2_3t1": "Ofrecemos funcionalidad efectiva y simple para nuestros clientes, basándonos en una gran experiencia en este ámbito empresarial. Por lo tanto, nuestro sistema está maximizado para trabajar específicamente con cafeterías. Hemos abordado muchos \"obstáculos\" que se encuentran en este tipo de negocio y ofrecemos soluciones listas que ayudarán a simplificar, acelerar y mejorar el trabajo de su cafetería.",
    "2_3t2": "También consideramos una ventaja nuestros precios bajos en comparación con los competidores; hoy en día son de los más bajos del mercado, y esto se debe a que buscamos dar a nuestros clientes el máximo por el mínimo; esta es la política de nuestra empresa.",
    "2_3t3": "Otra diferencia importante de la mayoría de los otros servicios es la ausencia de cualquier limitación en los volúmenes del sistema: solo en el sistema Kavapp puede crear una cantidad ilimitada de productos, personal y recetas técnicas sin costos adicionales.",

    "2_4": "Acuerdo de usuario y política de privacidad de Kavapp.",
    "2_4t1": "Puede familiarizarse con el acuerdo de usuario, que firma al registrarse en nuestro sitio web, en este <0>enlace</0>.",
    "2_4t2": "Puede familiarizarse con la política de privacidad de Kavapp en este <0>enlace</0>.",

    "2_5": "¿Es posible colaborar con la empresa Kavapp?",
    "2_5t1": "Sí, pero cada propuesta es considerada individualmente por la dirección. Si tiene una propuesta de colaboración, por favor descríbala y envíela a nuestra dirección de correo electrónico <0>info@kavapp.com</0>.",
    "2_5t2": "Procesaremos rápidamente su propuesta y le responderemos lo antes posible.",
}

const list3 = {
    "3": "Pago y tarifas.",
    "3_1": "¿Cómo se realiza el pago del sistema?",
    "3_1t": "Después de que finalice su período de prueba, selecciona su tarifa y elige el período de uso que desea pagar (el costo se calcula en función de la tarifa seleccionada y la cantidad de puntos de venta) y realiza el pago a través del servicio LiqPay directamente en la página de su gabinete personal.",

    "3_2": "¿En qué se diferencian las tarifas?",
    "3_2t1": "Nuestros planes tarifarios difieren solo en cierta funcionalidad que se incluye en cada una de estas tarifas.",
    "3_2t2": "La tarifa S es funcionalidad básica con todos los informes, no tiene la capacidad de calcular el costo de las posiciones.",
    "3_2t3": "La tarifa M es la funcionalidad de la tarifa S + cálculos financieros (cálculo del costo y cálculo de compras con dinero de la caja), sistema de descuentos y constructor de promociones.",
    "3_2t4": "La tarifa L es la funcionalidad de las tarifas S y M + constructor de posiciones de menú (parámetros y modificadores de bebidas), la posibilidad de vender desde el almacén y establecer diferentes menús y precios en diferentes puntos de venta.",

    "3_3": "¿Puede Kavapp emitir una factura separada para el pago?",
    "3_3t": "No, el pago se realiza solo directamente a través del servicio LiqPay en la página de su gabinete personal de Kavapp.",
    "3_4": "¿Qué tipos de pago se aceptan?",
    "3_4t": "Puede realizar el pago con cualquier tarjeta con la que trabaje el servicio LiqPay, incluidas las más comunes Visa y MasterCard.",
}

const list4 = {
    "4": "pRRO",
    "4_1": "¿En qué tarifas se incluye el pRRO y cómo se conecta y configura?",
    "4_1t": "El pRRO está incluido en todas las tarifas de Kavapp y no necesita pagar nada adicional para usarlo. Después de registrar su gabinete personal de Kavapp, recibirá instrucciones paso a paso para conectar y configurar el pRRO."
}

export const faq = {
    ...text,
    ...list1,
    ...list2,
    ...list3,
    ...list4
}