const text = {
    search: "Căutare",
    title: "FAQ (Întrebări frecvente)",
    link: "link"
}

const list1 = {
    "1": "Conectare, echipamente necesare, începerea lucrului.",
    "1_1": "Cum conectez sistemul Kavapp?",
    "1_1t": "Pentru a lucra cu sistemul nostru, trebuie doar să vă înregistrați pe site-ul nostru <0>Kavapp.com</0> și să descărcați aplicația <1>Kavapp Seller</1> pe dispozitivul de lucru. Toate setările sistemului le puteți face singur sau cu ajutorul suportului nostru tehnic.",

    "1_2": "Cu ce echipamente funcționează Kavapp?",
    "1_2t1": "Kavapp Admin funcționează în format web și poate fi deschis din orice browser pe orice dispozitiv.",
    "1_2t2": "Kavapp Seller funcționează în format de aplicație mobilă pe sistemele de operare Windows (10+, 64-bit), Android (minim 6, recomandat 9+) și iOS (minim 12, recomandat 15+).",
    "1_2t3": "Kavapp Delivery funcționează în format de aplicație mobilă pe Android (minim 6, recomandat 9+) și iOS (minim 12, recomandat 15+).",
    "1_2t4": "Aplicația Kavapp Seller suportă conectarea unei imprimante de bonuri (Bluetooth, USB, Wi-Fi) și a unui scanner de coduri de bare (Bluetooth, USB).",

    "1_3": "Este sistemul gata de utilizare după înregistrare?",
    "1_3t": "Da, imediat după înregistrare, veți primi o versiune completă a sistemului cu o perioadă de probă de 2 săptămâni gratuită pe orice tarif. În program sunt incluse imediat produse și rețete standard, pe care le puteți edita și ajusta pe durata perioadei de probă și după încheierea acesteia. Toate setările efectuate în timpul perioadei de probă vor fi păstrate după încheierea acesteia.",
    "1_4": "Angajații Kavapp configurează sistemul nostru sau instalează echipamentele în cafenea?",
    "1_4t": "Nu, lucrăm doar online și nu conectăm echipamente în cafeneaua dvs., însă, dacă aveți nevoie de ajutor pentru conectarea sau configurarea sistemului, vă puteți adresa suportului nostru tehnic și vom fi bucuroși să vă ajutăm.",
    "1_5": "Este necesar internet pentru a lucra cu aplicația?",
    "1_5t": "Internetul este necesar pentru a deschide și închide schimbul de lucru în aplicația Kavapp Seller. Dacă schimbul a fost deja început, aplicația poate funcționa offline (cu toate acestea, informațiile din contul personal nu vor fi sincronizate și vor fi sincronizate la conectarea dispozitivului la internet).",
    "1_6": "Pot achiziționa echipamente de la Kavapp?",
    "1_6t": "În acest moment, nu vindem niciun echipament, dar puteți obține recomandări cu privire la anumite echipamente de la suportul nostru tehnic.",
}

const list2 = {
    "2": "Despre compania Kavapp.",
    "2_1": "De cât timp funcționează serviciul Kavapp?",
    "2_1t": "Sistemul nostru funcționează din 2019, lucrăm constant pentru îmbunătățirea și extinderea serviciului nostru și nu intenționăm să ne oprim în viitor.",
    "2_2": "Cât de des se actualizează și îmbunătățește sistemul?",
    "2_2t": "Actualizări majore ale aplicațiilor apar lunar, dar putem emite corecții mai mici mult mai frecvent, toate acestea fiind necesare pentru a asigura funcționarea stabilă a sistemului și îmbunătățirea funcționalității. Încercăm să ascultăm clienții noștri pentru a oferi servicii cât mai confortabile și eficiente.",

    "2_3": "Care sunt principalele avantaje ale Kavapp față de alte sisteme POS pentru cafenele?",
    "2_3t1": "Oferim o funcționalitate eficientă și simplă pentru clienții noștri, bazată pe o vastă experiență în acest domeniu de afaceri, motiv pentru care sistemul nostru este orientat maxim pentru a lucra cu cafenele. Am analizat multe 'capcane' care apar în acest tip de afacere și oferim soluții gata făcute care vă vor ajuta să simplificați, să accelerați și să îmbunătățiți funcționarea cafenelei dvs.",
    "2_3t2": "De asemenea, considerăm că un alt avantaj al nostru este prețurile scăzute ale serviciilor noastre în comparație cu concurenții, astăzi acestea fiind printre cele mai mici de pe piață, deoarece dorim să oferim clienților noștri maximum pentru minimum - aceasta este politica companiei noastre.",
    "2_3t3": "O altă diferență importantă față de majoritatea celorlalte servicii este absența oricăror restricții privind volumele din sistem: doar în sistemul Kavapp puteți crea un număr nelimitat de produse, personal și rețete tehnice fără costuri suplimentare.",

    "2_4": "Termeni de utilizare și politica de confidențialitate Kavapp.",
    "2_4t1": "Puteți consulta termenii de utilizare, pe care îi semnați la înregistrarea pe site-ul nostru, la <0>?????</0>.",
    "2_4t2": "Puteți consulta politica de confidențialitate a Kavapp la <0>?????</0>.",

    "2_5": "Este posibilă colaborarea cu compania Kavapp?",
    "2_5t1": "Da, dar fiecare propunere este evaluată individual de conducere, dacă aveți o propunere de colaborare, vă rugăm să o descrieți și să o trimiteți la adresa noastră de e-mail <0>info@kavapp.com</0>.",
    "2_5t2": "Vă vom procesa rapid propunerea și vom oferi un răspuns cât mai curând posibil.",
}

const list3 = {
    "3": "Plată și tarife.",
    "3_1": "Cum se face plata sistemului?",
    "3_1t": "După ce perioada de probă expiră, alegeți tariful dorit și perioada de utilizare pe care doriți să o plătiți (costul este calculat pe baza tarifului selectat și a numărului de puncte de vânzare) și efectuați plata prin serviciul LiqPay direct pe pagina contului dvs. personal.",

    "3_2": "Care sunt diferențele dintre tarife?",
    "3_2t1": "Planurile noastre tarifare diferă doar prin anumite funcționalități incluse în fiecare dintre aceste tarife.",
    "3_2t2": "Tariful S - este funcționalitatea de bază cu toate rapoartele, nu include posibilitatea de a calcula costul pozițiilor.",
    "3_2t3": "Tariful M - este funcționalitatea tarifului S + calcule financiare (calculul costurilor și plata achizițiilor din casierie), sistem de discount și constructor de promoții.",
    "3_2t4": "Tariful L - este funcționalitatea tarifelor S și M + constructor de poziții de meniu (parametri și modificatori de băuturi), posibilitatea de a vinde din stoc și de a seta meniuri și prețuri diferite la diferite puncte de vânzare.",

    "3_3": "Poate Kavapp să emită o factură separată pentru plată?",
    "3_3t": "Nu, plata se face doar direct prin serviciul LiqPay pe pagina contului dvs. personal Kavapp.",
    "3_4": "Ce tipuri de plată sunt acceptate?",
    "3_4t": "Puteți efectua plata cu orice card acceptat de serviciul LiqPay, inclusiv cele mai comune Visa și MasterCard.",
}

const list4 = {
    "4": "pRRO",
    "4_1": "Ce tarife includ pRRO și cum îl conectez și configurez?",
    "4_1t": "pRRO este inclus în toate tarifele Kavapp și nu trebuie să plătiți nimic suplimentar pentru utilizarea acestuia. Instrucțiuni pas cu pas pentru conectarea și configurarea pRRO vor fi primite după înregistrarea contului personal Kavapp."
}

export const faq = {
    ...text,
    ...list1,
    ...list2,
    ...list3,
    ...list4
}