export const pages = {
    about_us: {
        banner: "Личный помощник для владельца кофейни!",
        description_first: "Kavapp - многофункциональная и понятная система для автоматизации кафе, миникофеен, а также автокофеен.",
        description_second: "Наша система даст вам возможность за невысокую цену настроить и организовать рабочий процесс в своем бизнесе.",
        subtitle: "Kavapp - легко, доступно, выгодно!",
        advantages: {
            low_price: "Низкая цена по сравнению с другими сервисами. Возможность выбрать оптимальный тариф для вашего типа заведения",
            low_price_mini: "Низкая цена",
            pppo: "Работа с пРРО – открывайте фискальные смены и фискализируйте проданные чеки в налоговой прямо с рабочего устройства, согласно действующему законодательству",
            pppo_mini: "Поддержка пРРО",
            tech_card: "Создание технологических карт в неограниченном количестве",
            tech_card_mini: "Создание тех. карт",
            wage: "Расчет зарплаты, начисления штрафов, премий, авансов",
            wage_mini: "Расчет зарплаты",
            control: "Контроль работы бариста, логиста и движение товара на складе",
            control_mini: "Контроль работы кофейни",
            rediscount: "Возможность проводить переучеты на торговых точках и на складе",
            rediscount_mini: "Возможность переучета",
            sale_from_warehouse: "Функция продажи товара со склада",
            sale_from_warehouse_mini: "Продажа со склада",
            charts: "Большое количество графиков и отчетов для контроля вашего бизнеса",
            charts_mini: "Большое количество графиков",
            messenger: "Мессенджер - внутренняя переписка между бариста, логистами и администратором в рабочее время, не отвлекаясь от рабочего процесса",
            messenger_mini: "Мессенджер",
            cooking: "Предварительное приготовление блюд/позиций меню/ингредиентов по технологической карте, для дальнейших продаж или использования на торговой точке",
            cooking_mini: "Приготовление блюд по тех. картам",
            device: "Высокая совместимость приложения с различными устройствами – система Kavapp поддерживает устройства на ОС Android, iOS и Windows",
            device_mini: "Работает на Android, iOS, Windows",
        },
        categories: {
            title: "Система разделена на 3 рабочих пространства",
            admin_title: "- Личный кабинет (Admin)",
            admin_description: " – рабочее пространство администратора",
            seller_title: "- Программа для продажи (Seller)",
            seller_description: " – рабочее пространство бариста",
            delivery_title: "- Логистическая программа, склад и доставка (Delivery)",
            delivery_description: " – рабочее пространство логиста",
        },
        head: {
            title: 'Kavapp – система автоматизации кофейни. CRM система для кофейни',
            description: 'Автоматизация кофейни с Kavapp. Система для автоматизации кафе, миникафе и автокафе. Подключить систему для автоматизации кофейни онлайн'
        }
    },
    portfolio: {
        portfolio_main: {
            seller: "Программа для бариста",
            delivery: "Программа для логиста",
            admin: "Программа для администратора",
            head: {
                title: 'Выбрать CRM для автоматизации кофейни. Kavapp - автоматизируй кофейню',
                description: 'Внедрение CRM системы для кафе. Как выбрать систему для автоматизации кофейни. Подключение программы для автоматизации кофейни. Поддержка программного РРО'
            }
        },
        portfolio_delivery: {
            description: "Kavapp Delivery – программа создана специально для облегчения и контроля работы Вашего логиста. " +
                "Kavapp Delivery предназначена для контроля движения товаров от склада до кафе, а также всего, " +
                "что касается складской логистики. В комплексе работы всей системы, логист оприходует товар на склад," +
                " со склада с помощью программы, работник фиксирует количество привезенного товара на кофейню, знает" +
                " сколько товара ушло на приготовление напитков – соответственно может видеть количество товара, " +
                "которое осталось на кофейне в режиме реального времени через программу Kavapp Delivery " +
                "(аналогичный функционал находится в рабочем пространстве администратора).",
            post_description: "Kavapp Delivery – логистика Вашего бизнеса.",
            description_slides: {
                first_slide: "Главная страница Delivery – на этой странице логист может пользоваться мессенджером, принимать" +
                    " заказы от бариста, подтверждать списание/инкассации и закупки на торговых точках.",
                second_slide: 'Меню "Склад" - все, что касается логистики склада – наличие, переучеты, оприходование и даже' +
                    ' продажи со склада. Есть возможность формировать отчеты по движению товаров.',
                three_slide: "Экран формирования заказа - удобно формируйте и отгружайте заказы с помощью приложения Kavapp Delivery",
                fourth_slide: 'Меню "Точки продаж" - здесь логист может проверять наличие товара на торговых точках, ' +
                    'произвести переучет на точке и посмотреть товародвижение.',
                fifth_slide: "Экран настроек Delivery – все настройки, касающиеся приложения в одном месте. Быстрое изменение" +
                    " темы приложения по вкусу, настройка безопасности, управление уведомлениями и изменение профиля работника."
            },
            head: {
                title: 'Программа контроля остатков в кафе. Контролируй логистику с Kavapp',
                description: 'Kavapp Delivery – программа и рабочее пространство логиста. Легкий контроль остатков и перемещение товаров в кафе. Облегчение работы логиста по Kavapp'
            }
        },
        portfolio_seller: {
            description: "Kavapp Seller - часть системы Kavapp, через которую непосредственно производятся все продажи с Вашей кофейни. " +
                "Удобные и быстрые продажи, возможность создавать заказ товара, инкассация наличных денег, закупки товара на точку, " +
                "любые списания и много другого функционала, который пригодится Вашему работнику. Контролируйте все продажи " +
                "и рабочее время бариста благодаря Kavapp Seller.",
            post_description: "Kavapp Seller – удобно, быстро и надежно.",
            link_apk: {
                description_one: "Альтернативная загрузка на Android ",
                description_two: ", на Windows ",
                link: "здесь",
            },
            description_slides: {
                first_slide: "Главный экран – основное рабочее место бариста у Seller. На этом экране бариста выбирает позиции, " +
                    "которые нужно добавить в чек. При необходимости бариста может использовать поиск, чтобы быстро" +
                    " найти нужный товар, и отложить чек (задержав кнопку продать на 2 секунды)",
                second_slide: "Экран расчета. На этом экране бариста указывает способ оплаты, при необходимости проводит скидку" +
                    " и рассчитывает сдачу, которую нужно отдать клиенту.",
                three_slide: "Экран функций – на этом экране бариста может создавать заказы/закупки/списания и инкассации на " +
                    "торговой точке. Также работник может просмотреть наличие товара на торговой точке, зарегистрировать " +
                    "бонусную карту для клиента и просмотреть всю финансовую отчетность за рабочую смену.",
                fourth_slide: "Экран проданных чеков – здесь барист может просмотреть всю историю проданных за текущую рабочую " +
                    "смену чеков, создать чек возврата или отменить последний проданный чек.",
                fifth_slide: "Экран настроек - в этом меню можно просмотреть и изменить все настройки, касающиеся приложения " +
                    "Seller, синхронизировать данные с админкой и подключить принтер чеков.",
            },
            head: {
                title: 'Удобная программа для автоматизации кофейни. Удобные и быстрые продажи',
                description: 'Kavapp Seller – программа и рабочее пространство для бариста. Интуитивно понятный интерфейс для работников, легкий в освоении и работе'
            }
        },
        portfolio_admin: {
            description: 'Kavapp Admin – главная часть сервиса Kavapp. В этом рабочем пространстве Вы производите все' +
                ' необходимые настройки системы и торговых точек, создаете меню, цены, тех карты. Большое количество' +
                ' графиков и отчетов поможет Вам быстро и точно анализировать Ваш бизнес. В Admin Вы также добавляете' +
                ' всех своих работников, настраиваете дисконтную систему, начисляете зарплаты и, при необходимости,' +
                ' подтверждаете заказ/списание/инкассацию и т.д.',
            post_description: 'Kavapp Admin – мощный инструмент в руках администратора',
            description_slides: {
                first_slide: 'Главная страница личного кабинета - краткая статистика по текущим рабочим сменам, общие ' +
                    'сведения и графики продаж на Ваших торговых точках.',
                second_slide: 'Меню "Точки продаж" - создавайте свои торговые точки, настраивайте меню, цены, заработную плату и функционал на каждой точке.',
                three_slide: 'Меню "Работники" - на этом экране Вы добавляете персонал, работающий на Ваших торговых точках ' +
                    'через программу Kavapp Seller и указываете их заработную плату - можно указывать разную заработную' +
                    ' плату для каждого работника отдельно.',
                fourth_slide: 'Меню "Логисты" - экран, на котором Вы добавляете Ваших логистов, которые будут работать в' +
                    ' приложении Kavapp Delivery (здесь Вы можете получить код подключения логиста).',
                fifth_slide: 'Меню "Ингредиенты" - экран, на котором Вы создаете все ингредиенты, из которых затем ' +
                    'формируются технические карты Ваших напитков и продуктов.',
                sixth_slide: 'Меню "Напитки" – это меню всех напитков, продаваемых на Вашей торговой точке. Здесь Вы выбираете' +
                    ' посуду, используемую для приготовления, указываете цену напитка и ингредиенты, входящие в него.',
                seventh_slide: 'Меню "Продукты" – экран, на котором Вы создаете все продукты, которые будут продаваться на' +
                    ' Ваших торговых точках (эти позиции состоят из ингредиентов, списываемых при продаже).',
                eighth_slide: 'Меню "Товары" - это товары, продаваемые на Ваших торговых точках, товар может быть' +
                    ' как поштучным, так и развесом (эти позиции не состоят из ингредиентов).',
                ninth_slide: 'Меню "Переучет" - экран, на котором Вы можете провести переучет на торговой точке, указав' +
                    ' фактические показатели наличия товаров, ингредиентов и посуды.',
                tenth_slide: 'Меню "Скидки" – экран, на котором Вы создаете все виды скидок, которые могут использоваться' +
                    ' для персональных карт Ваших постоянных клиентов.',
            },
            head: {
                title: 'Рабочее пространство администратора кофейни. CRM для автоматизации кофейни',
                description: 'Kavapp Admin – рабочее пространство администратора. Автоматизация ведения кофейни: технологические карты, меню, начисление заработной платы, остатки'
            }
        },

    },
    tariffs: {
        banner: {
            main_title: {
                strong: "Самые выгодные ",
                light: "цены системы программ ведения кофейни",
            },
            secondary_title: {
                strong: "От",
                currency: "грн/месяц",
                currency_usd: "USD/месяц",
                for_shop: "за кофейню"
            },
        },

        card_title_currency: {
            uk: "грн/месяц",
            usd: "USD/месяц",
        },
        card_title_every_point: "за торговую точку",

        button: {
            open_button: "Подробнее",
            close_button: "Свернуть",
        },
        priceSelect: "Цена при оплате за: ",
        period: {
            "1": "1 месяц",
            "3": "3 месяца",
            "6": "6 месяцев",
            "12": "12 месяцев",
        },
        head: {
            title: 'Тарифы на систему автоматизации для кофейни Kavapp',
            description: 'Цены на CRM для автоматизации кофейни. Недорогая программа для автоматизации кофейни. Самые выгодные цены системы программ ведения кофейни'
        }
    },
    contact: {
        left_block: {
            title: "Связаться с нами",
            main_description: "Если у вас возникли срочные вопросы к нам или проблемы в работе с программой, вы можете связаться" +
                " с нами удобным для вас способом.",
            phone: "Телефон:",
            email: "Электронная почта:",
            work_schedule: "График работы:",
        },
        right_block: {
            title: "Написать письмо",
            name: "Имя",
            mail: "Номер телефона или электронная почта",
            mail_usd: "Электронная почта",
            mail_mini: "Телефон или e-mail",
            mail_mini_usd: "E-mail",
            message: "Ваш вопрос или сообщение",
            error_message: "Не указаны данные",
            error_contact: "Не указаны допустимые данные",
            sent_message: "Ваше сообщение отправлено!",
            no_message_sent: "Ваше сообщение не отправлено! Попробуйте отправить еще раз!",
        },
        head: {
            title: 'CRM Kavapp - свяжитесь с нами для автоматизации кофейни',
            description: 'Подключить программу Kavapp – свяжитесь с нами для расчета и подключения. Автоматизация кофейни – это просто и недорого вместе с Kavapp'
        }
    },
}

export default pages

export const tariff_list = [
    "Неограниченное количество тех. карт",
    "Зарплата, авансы, штрафы",
    "Склад и логистика",
    "Настройка безопасности",
    "Встроенный мессенджер",
    "Предварительное приготовление",
    "Дисконтная система",
    "Конструктор акций",
    "Подсчеты себестоимости",
    "Модификаторы напитков",
    "Продажа со склада",
    "Различные цены на кофейнях"
]

export const add_tariff_list = [
    "Базовая отчетность",
    "Полная отчетность",
    "ПРРО",
]