const text = {
    search: "Пошук",
    title: "FAQ (Питання та відповіді)",
    link: "посиланням"
}

const list1 = {
    "1": "Підключення, необхідне обладнання, початок роботи.",
    "1_1": "Як підключити систему Kavapp?",
    "1_1t": "Для роботи з нашою системою Вам достатньо зареєструватись на нашому сайті <0>Kavapp.com</0> та завантажити додаток  <1>Kavapp Seller</1> на робочий пристрій. Всі налаштування системи Ви можете провести самостійно або за допомогою нашої тех.підтримки. ",

    "1_2": "З яким обладнанням працює Kavapp?",
    "1_2t1": "Kavapp Admin працює у форматі веб-сайту та може бути відкритий з будь-якого браузера на будь-якому пристрої. ",
    "1_2t2": "Kavapp Seller працює у форматі мобільного застосунку на операційних системах Windows (10+, 64-розрядна), Android (мінімальний 6, рекомендований 9+) та iOS (мінімальний 12, рекомендований 15+).",
    "1_2t3": "Kavapp Delivery працює у форматі мобільного застосунку на Android (мінімальний 6, рекомендований 9+) та iOS (мінімальний 12, рекомендований 15+). ",
    "1_2t4": "Програма Kavapp Seller підтримує підключення принтеру чеків (Bluetooth, USB, Wi-Fi) та сканер штрих-кодів (Bluetooth, USB)",

    "1_3": "Чи готова система до роботи після реєстрації? ",
    "1_3t": "Так, одразу після реєстрації Ви отримуєте повноцінну версію системи з пробним періодом 2 тижні безкоштовно на будь-якому тарифі. В програмі одразу пристутні стандартні товари та тех. карти, які Ви можете редагувати та налаштовувати протягом пробного періоду та після його завершення. Всі налаштування які Ви проведете під час пробного періоду збережуться після його завершення.",
    "1_4": "Чи налаштовують працівники Kavapp нашу систему, чи встановлють обладнання на кав'ярні?",
    "1_4t": "Ні, ми працюємо лише у форматі он-лайн і не підключаємо обладнання у Вашій кав'ярні, проте, якщо Вам потрібна допомога у підключенні або налаштуванні системи, Ви можете звернутись у нашу тех.підтримку, будемо раді допомогти.",
    "1_5": "Чи потрібен інтернет для роботи з програмою? ",
    "1_5t": "Інтернет потрібен для відкриття та закриття робочої зміни в програмі Kavapp Seller, якщо зміну вже було розпочато, додаток може працювати офлайн (при цьому інформація в особистому кабінеті не буде синхронізована і синхронізується при підключенні пристрою до інтернету).",
    "1_6": "Чи можна придбати у Kavapp обладнання? ",
    "1_6t": "На даний момент ми не продаємо жодного обладнання, але Ви можете отримати рекомендації стосовно певного обладнання у нашої тех.підтримки. ",
}

const list2 = {
    "2": "Про компанію Kavapp.",
    "2_1": "Як давно працює сервіс Kavapp? ",
    "2_1t": "Наша система працює починаючи з 2019 року, ми постійно працюємо над покращенням та розширенням нашого сервісу і не плануємо зупинятись у майбутньому. ",
    "2_2": "Як часто оновлюється та покращується система? ",
    "2_2t": "Глобальні оновлення додатків виходять щомісяця, при цьому ми можемо випускати менші правки значно частіше, все це необхідно для забезпечення стабільної роботи системи та покращення функціоналу. Ми стараємось прислуховуватись до наших клієнтів щоб забезпечувати максимально комфортні та ефективні послуги. ",

    "2_3": "В чому головні переваги Kavapp перед іншими POS-системами для кав'ярні?",
    "2_3t1": "Ми надаємо ефективний і простий функціонал для наших клієнтів керуючись великим досвідом у даній сфері ведення бізнесу, тому наша система максимально орієнтована для роботи саме з кав'ярнями. Ми пропрацювали багато \"підводних каменів\", які зустрічаються у такому бізнесі та надаємо готові рішення, які допоможуть спростити, прискорити та покращити роботу Вашої кав'ярні.",
    "2_3t2": "Також своєю перевагою ми вважаємо низькі ціни на наші послуги порівняно з конкурентами, на сьогодні - вони одні з найнижчих на ринку і це пов'язано з тим, що ми прагнемо дати нашим клієнтам максимум за мінінмум - це політика нашої компанії. ",
    "2_3t3": "Ще одна важлива відмінність від більшості інших сервісів - відсутність будь-яких обмежень по об'ємах в системі: тільки в системі Kavapp Ви можете створювати необмежену кількість товарів, персоналу та тех.карт без додаткових витрат. ",

    "2_4": "Угода користувача та політика конфіденційності Kavapp.",
    "2_4t1": "Ознайомитись з угодою користувача, яку Ви підписуєте при реєстрації на нашому сайті Ви можете за <0>?????</0>.",
    "2_4t2": "Ознайомитись з політикою конфіденційності Kavapp Ви можете за <0>?????</0>.",

    "2_5": "Чи можлива співпраця з компанією Kavapp?",
    "2_5t1": "Так, але кожна пропозиція розглядається керівництвом індивідуально, якщо Ви маєте пропозицію стосовно співпраці, будь ласка опишіть її та відправте на нашу електронну адресу <0>info@kavapp.com</0>.",
    "2_5t2": "Ми швидко опрацюємо Вашу пропозицію та надамо відповідь у найкоротші терміни.",
}

const list3 = {
    "3": "Оплата і тарифи.",
    "3_1": "Як відбувається оплата системи? ",
    "3_1t": "Після того як у Вас закінчується пробний період, Ви обираєте свій тариф та обираєте термін користування, який хочете оплатити (вартість рахується виходячи з обраного тарифу та кількості торгових точок) і проводите платіж через сервіс LiqPay прямо на сторінці особистого кабінету. ",

    "3_2": "Чим відрізняються тарифи? ",
    "3_2t1": "Наші тарифні плани відрізняються лише певним функціоналом, який входить у кожний з цих тарифів. ",
    "3_2t2": "Тариф S - це базовий функціонал з усією звітністю, не має в собі можливості розраховувати собівартість позицій. ",
    "3_2t3": "Тариф M - це функціонал тарифу S + фінансові розрахунки (підрахунки собівартості та розрахунок за закупівлі коштами з каси), дисконтна система та конструктор акцій. ",
    "3_2t4": "Тариф L - це функціонал тарифів S та M + конструктор позицій меню (параметри і модифікатори напоїв), можливість вести продажі зі складу та встановлювати різні меню і ціни на різних торгових точках.",

    "3_3": "Чи може Kavapp виставити окремий рахунок на оплату? ",
    "3_3t": "Ні, оплата проводиться тільки напряму через сервіс LiqPay на сторінці Вашого осбистого кабінету Kavapp.",
    "3_4": "Які типи оплати приймаються? ",
    "3_4t": "Ви можете провести оплату з будь-якої картки з якою працює сервіс LiqPay, в тому числі найпоширеніші Visa та MasterCard. ",
}

const list4 = {
    "4": "пРРО",
    "4_1": "В які тарифи входить пРРО та як його підключити та налаштувати?",
    "4_1t": "пРРО входить у всі тарифи Kavapp і для його використання нічого доплачувати не потрібно. Покрокові інструкції по підключенню і налаштуванню пРРО Ви отримаєте після реєстрації особистого кабінету Kavapp."
}


export const faq = {
    ...text,
    ...list1,
    ...list2,
    ...list3,
    ...list4
}