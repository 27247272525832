import {useTranslation} from "react-i18next";
import {useMemo} from "react";

import SliderForPortfolio from "../../../pageComponents/SliderForPortfolio/SliderForPortfolio";
import AppBadge from "../../../pageComponents/AppBadge/AppBadge";
import AppBadgeWrapper from "../../../pageComponents/AppBadge/AppBadgeWrapper";

import "./portfolio-seller.scss"

import firstSlide from "../../../../img/image/seller/first-slide.png"
import secondSlide from "../../../../img/image/seller/second-slide.png"
import threeSlide from "../../../../img/image/seller/three-slide.png"
import fourthSlide from "../../../../img/image/seller/fourth-slide.png"
import fifthSlide from "../../../../img/image/seller/fifth-slide.png"

import seller_1_en from "../../../../img/image/seller/seller_1_en.png"
import seller_2_en from "../../../../img/image/seller/seller_2_en.png"
import seller_3_en from "../../../../img/image/seller/seller_3_en.png"
import seller_4_en from "../../../../img/image/seller/seller_4_en.png"
import seller_5_en from "../../../../img/image/seller/seller_5_en.png"


const slidersList = {
    en: [seller_1_en, seller_2_en, seller_3_en, seller_4_en, seller_5_en],
    ua: [firstSlide, secondSlide, threeSlide, fourthSlide, fifthSlide],
    text: ["first_slide", "second_slide", "three_slide", "fourth_slide", "fifth_slide"]
}


function PortfolioSeller() {
    const {t, i18n} = useTranslation()

    const slides = useMemo(() => {
        const lng = (i18n.language === 'en' || i18n.language === 'es') ? 'en' : 'ua'

        return Array.isArray(slidersList[lng]) ? slidersList[lng].map((item, index) => {
            return {
                img: (<img src={item} className="tablet" alt="Kavapp Seller"/>),
                text: (
                    <p className={'description'}>
                        {t(`pages.portfolio.portfolio_seller.description_slides.${slidersList.text[index]}`)}
                    </p>)
            }
        }) : []
    }, [i18n.language])

    return (
        <div className="seller">
            <h1 className="title">Kavapp Seller</h1>
            <h3 className="subtitle">{t("components.programForSeller")}</h3>
            <p className="description-seller-page">{t("pages.portfolio.portfolio_seller.description")}</p>
            <p className="sub-description">{t("pages.portfolio.portfolio_seller.post_description")}</p>
            <p className={"minimum-characteristics"}>
                <span>{t("components.system_requirements.android")}</span><br/>
                <span>{t("components.system_requirements.ios")}</span><br/>
                <span>{t("components.system_requirements.windows")}</span>
            </p>

            <AppBadgeWrapper>
                <AppBadge href='https://play.google.com/store/apps/details?id=com.kavapp.seller' badge='google'/>
                <AppBadge href='https://apps.apple.com/us/app/id1622974850' badge='apple'/>
                <AppBadge href='https://www.microsoft.com/store/apps/9P63P8F9XPM6' badge='microsoft'/>
            </AppBadgeWrapper>


            <p className="another-link">
                {t('pages.portfolio.portfolio_seller.link_apk.description_one')}
                <a className="link"
                   href="https://download.kavapp.com/kavapp-seller.apk"> {t('pages.portfolio.portfolio_seller.link_apk.link')}</a>
                {t('pages.portfolio.portfolio_seller.link_apk.description_two')}
                <a className="link"
                   href="https://download.kavapp.com/kavapp-seller.exe"> {t('pages.portfolio.portfolio_seller.link_apk.link')}</a>
            </p>
            <SliderForPortfolio slides={slides}/>
        </div>
    )
}

export default PortfolioSeller
