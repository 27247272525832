import React, {createContext, useState} from 'react';
import {getTheme} from "../../utils/utils";

export const GlobalContext = createContext({})

const ContextProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({
        country: '',
        prices: null,
        theme: getTheme(),
        windowIsSmall: window.innerWidth < 760
    })

    return (
        <GlobalContext.Provider value={{globalState, setGlobalState}}>
            {children}
        </GlobalContext.Provider>
    );
};

export default ContextProvider;