import React from 'react'
import {Route, Navigate} from 'react-router-dom'

import AboutUs from "../components/pages/AboutUs/AboutUs";
import Portfolio from "../components/pages/Portfolio/Portfolio";
import Tariffs from "../components/pages/Tarriffs/Tariffs";
import Contact from "../components/pages/Contact/Contact";
import PortfolioDelivery from "../components/pages/Portfolio/PortfolioDelivery/PortfolioDelivery";
import PortfolioSeller from "../components/pages/Portfolio/PortfolioSeller/PortfolioSeller";
import PortfolioAdmin from "../components/pages/Portfolio/PortfolioAdmin/PortfolioAdmin";
import Faq from "../components/pages/Faq/Faq";


export default function getRoutesList(locale) {
    return (
        <>
            <Route path={`/`} element={<Navigate to={`/${locale}/about_me`}/>}/>
            <Route path={`/${locale}/about_me`} element={<AboutUs/>}/>

            {/*==============portfolio===========================*/}
            <Route path={`/${locale}/portfolio`} element={<Portfolio/>}/>
            <Route path={`/${locale}/portfolio/admin`} element={<PortfolioAdmin/>}/>
            <Route path={`/${locale}/portfolio/sale`} element={<PortfolioSeller/>}/>
            <Route path={`/${locale}/portfolio/delivery`} element={<PortfolioDelivery/>}/>

            <Route path={`/${locale}/tariffs`} element={<Tariffs/>}/>
            <Route path={`/${locale}/contact`} element={<Contact/>}/>
            <Route path={`/${locale}/faq`} element={<Faq/>}/>

            {/* If not found*/}
            <Route path={`*`} element={<Navigate to={`/${locale}/about_me`}/>}/>
        </>
    )
}