import {useContext, useState} from "react";
import {Link, NavLink,} from "react-router-dom";
import {useTranslation} from "react-i18next";

import ButtonToPrivatAccount from "../Buttons/ButtonToPrivatAccount/ButtonToPrivatAccount";
import Logo from "./components/Logo/Logo";
import FaqButton from "../Buttons/FaqButton/FaqButton";
import ChangeModeButton from "../Buttons/ChangeModeButton/ChangeModeButton";
import LanguageSelect from "./components/LanguageSelect/LanguageSelect";
import MobileNavigationMenu from "./components/MobileNavigationMenu/MobileNavigationMenu";

import "./Header.scss"
import {GlobalContext} from "../../../app/providers/ContextProvider";


function Header() {
    const {t, i18n} = useTranslation()

    const [isOpenNavigation, setIsOpenNavigation] = useState(false);
    const {globalState: {windowIsSmall}} = useContext(GlobalContext)


    const toggleOpenNavigation = () => {
        setIsOpenNavigation(prev => !prev)
    }

    if (windowIsSmall) return (
        <header className="header gradient">
            <div className="header__container">
                <div className="open-navigation-button"
                     onClick={toggleOpenNavigation}>
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.375 26.25H30.625V23.3333H4.375V26.25ZM4.375 18.9583H30.625V16.0417H4.375V18.9583ZM4.375 8.75V11.6667H30.625V8.75H4.375Z"/>
                    </svg>
                </div>
                <Logo/>
                <div className="right-top-block">
                    <div className="account-icon">
                        <ButtonToPrivatAccount
                            svgIcon={
                                <svg width="31" height="30" viewBox="0 0 31 30" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M15.7419 14.4159C13.6462 14.4159 11.9315 13.7614 10.5978 12.4523C9.26411 11.1433 8.59728 9.46029 8.59728 7.40325C8.59728 5.34621 9.26411 3.66318 10.5978 2.35416C11.9315 1.04514 13.6462 0.390625 15.7419 0.390625C17.8377 0.390625 19.5524 1.04514 20.8861 2.35416C22.2198 3.66318 22.8866 5.34621 22.8866 7.40325C22.8866 9.46029 22.2198 11.1433 20.8861 12.4523C19.5524 13.7614 17.8377 14.4159 15.7419 14.4159ZM0.5 29.4229V25.0283C0.5 23.844 0.801663 22.831 1.40499 21.9895C2.00832 21.148 2.78629 20.5091 3.73891 20.0727C5.86643 19.1377 7.90663 18.4364 9.8595 17.9689C11.8124 17.5014 13.7732 17.2677 15.7419 17.2677C17.7107 17.2677 19.6636 17.5092 21.6006 17.9923C23.5375 18.4754 25.5698 19.1689 27.6973 20.0727C28.6817 20.5091 29.4756 21.148 30.0789 21.9895C30.6822 22.831 30.9839 23.844 30.9839 25.0283V29.4229H0.5ZM3.35786 26.6178H28.126V25.0283C28.126 24.5296 27.9752 24.0543 27.6735 23.6024C27.3718 23.1505 26.9987 22.8154 26.5542 22.5973C24.5219 21.6311 22.6643 20.9688 20.9813 20.6104C19.2984 20.2519 17.5519 20.0727 15.7419 20.0727C13.932 20.0727 12.1696 20.2519 10.4549 20.6104C8.74017 20.9688 6.88256 21.6311 4.88206 22.5973C4.4375 22.8154 4.07233 23.1505 3.78654 23.6024C3.50076 24.0543 3.35786 24.5296 3.35786 25.0283V26.6178ZM15.7419 11.6108C16.9803 11.6108 18.0044 11.2134 18.8141 10.4187C19.6239 9.62391 20.0287 8.61877 20.0287 7.40325C20.0287 6.18773 19.6239 5.18258 18.8141 4.38782C18.0044 3.59306 16.9803 3.19567 15.7419 3.19567C14.5035 3.19567 13.4795 3.59306 12.6697 4.38782C11.86 5.18258 11.4551 6.18773 11.4551 7.40325C11.4551 8.61877 11.86 9.62391 12.6697 10.4187C13.4795 11.2134 14.5035 11.6108 15.7419 11.6108Z"/>
                                </svg>
                            }
                        />
                    </div>
                    <FaqButton/>
                    <ChangeModeButton/>
                </div>
            </div>
            {isOpenNavigation && <MobileNavigationMenu onClose={() => setIsOpenNavigation(false)}/>}
        </header>
    )

    return (
        <header className="header gradient">
            <div className="header__container">
                <Logo/>
                <nav className={"nav-list"}>
                    <NavLink to={`/${i18n.language}/about_me`}
                             className={({isActive}) => isActive ? "nav-list-item active" : "nav-list-item"}>
                        {t('words.about_us')}
                    </NavLink>

                    <NavLink to={`/${i18n.language}/portfolio`}
                             className={({isActive}) => isActive ? "nav-list-item active" : "nav-list-item"}>
                        {t('words.portfolio')}

                        <div className="nav-list-submenu light-bg">
                            <Link to={`/${i18n.language}/portfolio/admin`}
                                  className={"nav-list-item active"}>
                                {t("components.header.admin")}
                            </Link>
                            <Link to={`/${i18n.language}/portfolio/sale`}
                                  className={"nav-list-item active"}>
                                {t("components.header.sale")}
                            </Link>
                            <Link to={`/${i18n.language}/portfolio/delivery`}
                                  className={"nav-list-item active"}>
                                {t("components.header.delivery")}
                            </Link>
                        </div>
                    </NavLink>
                    <NavLink to={`/${i18n.language}/tariffs`}
                             className={({isActive}) => isActive ? "nav-list-item active" : "nav-list-item"}>
                        {t('words.tariffs')}
                    </NavLink>
                    <NavLink to={`/${i18n.language}/contact`}
                             className={({isActive}) => isActive ? "nav-list-item active" : "nav-list-item"}>
                        {t('words.contact')}
                    </NavLink>
                </nav>
                <div className="right-top-block">
                    <ButtonToPrivatAccount className="redirect-to-adminpanel-button"/>
                    <FaqButton/>
                    <ChangeModeButton/>
                    <LanguageSelect/>
                </div>
            </div>
        </header>
    )
}


export default Header