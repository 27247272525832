const components = {
    programForLogisticians: "Programul și spațiul de lucru al logisticianului",
    programForSeller: "Programul și spațiul de lucru pentru barista",
    programForAdmin: "Spațiul de lucru al administratorului",
    header: {
        private_account: "Cont personal",
        admin: "Kavapp Admin (Pentru administrator)",
        sale: "Kavapp Seller (Pentru vânzări)",
        delivery: "Kavapp Delivery (Pentru logistician)",
    },
    footer: {
        connect: "Rețelele noastre sociale",
        language_change: "Schimbă limba",
        we_accept: "Acceptăm ",
        phone: {
            title: "Telefonul nostru: ",
            working_days: "lun-vin",
        },
        applicationDeveloper: "Dezvoltatorul site-ului și sistemului Kavapp"
    },
    system_requirements: {
        android: "Versiunea minimă pentru Android - 6 (recomandată 9)",
        ios: "Versiunea minimă pentru iOS - 12 (recomandată 15)",
        windows: "Versiunea minimă pentru Windows - 10, 64-bit",
    }
}

export default components