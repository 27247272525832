export const pages = {
    about_us: {
        banner: "A personal assistant for a coffee shop owner!",
        description_first: "Kavapp is a multifunctional and intuitive system for automating coffee shops, mini-cafes and car cafes.",
        description_second: "Our system will give you the opportunity to set up and organize the workflow in your business at a low price.",
        subtitle: "Kavapp - easy, affordable, profitable!",
        advantages: {
            low_price: "Low price compared to other services. Possibility to choose the optimal tariff for your type of establishment",
            low_price_mini: "Low price",
            pppo: "Work with pRRO - open fiscal work shifts and fiscalize sold checks in the tax office directly from the work device, in accordance with current legislation",
            pppo_mini: "Support of pRRO",
            tech_card: "Creation of technological cards in unlimited quantities",
            tech_card_mini: "Creation of tech. cards",
            wage: "Salary calculation, accrual of fines, bonuses, advances",
            wage_mini: "Salary calculation",
            control: "Control of the barista, logistician and movement of goods in the warehouse",
            control_mini: "Control of the coffee shop",
            rediscount: "Ability to make recalculations at the point of sale and in the warehouse",
            rediscount_mini: "Possibility of recalculation",
            sale_from_warehouse: "The function of selling goods from the warehouse",
            sale_from_warehouse_mini: "Sale from warehouse",
            charts: "Lots of charts and reports to monitor your business",
            charts_mini: "Many graphs",
            messenger: "Messenger - internal correspondence between baristas, logisticians and administrators during working hours, without distracting from the work process",
            messenger_mini: "Messenger",
            cooking: "Pre-cooking of dishes/menu items/ingredients according to the technological card, for further sales or use at the point of sale",
            cooking_mini: "Preparation of dishes according to the tech. card",
            device: "High compatibility of the program with various devices - the Kavapp system supports Android, iOS and Windows devices",
            device_mini: "Works on Android, iOS, Windows",
        },
        categories: {
            title: "The system is divided into 3 workspaces",
            admin_title: "- Personal account (Admin)",
            admin_description: " - administrator's workspace",
            seller_title: "- Sales program (Seller)",
            seller_description: " - barista workspace",
            delivery_title: "- Logistics program, warehouse and delivery (Delivery)",
            delivery_description: " - a logistician's workspace",
        },
        head: {
            title: 'Kavapp is a system for coffee shop automation. CRM system for a coffee shop',
            description: 'Coffee shop automation with Kavapp. A system for automating coffee shops, mini-cafes and car-cafes. Connect the system for automating the coffee shop online'
        }
    },
    portfolio: {
        portfolio_main: {
            seller: "Program for barista",
            delivery: "Program for logistician",
            admin: "Program for administrator",
            head: {
                title: 'Choose a CRM for coffee shop automation. Kavapp - automate the coffee shop',
                description: 'Implementation of a CRM system for a coffee shop. How to choose a system for automating a coffee shop. Connecting the program for automating the coffee shop. Software PRO support'
            }
        },
        portfolio_delivery: {
            description: "Kavapp Delivery is a program created specifically to facilitate and control the work of your logistician." +
                " Kavapp Delivery is designed to control the movement of goods from the warehouse to the coffee shop, " +
                "as well as everything related to warehouse logistics. In the complex of the entire system," +
                " the logistician places the goods in the warehouse, from the warehouse using the program, the employee" +
                " records the amount of goods brought to the coffee shop, knows how much of the goods was used to" +
                " prepare drinks - accordingly, he can see the amount of goods left in the coffee shop" +
                " in real time through the Kavapp Delivery program (similar functionality is available in the administrator's workspace).",
            post_description: "Kavapp Delivery - the logistics of your business.",
            description_slides: {
                first_slide: "Delivery main page - on this page, the logistician can use the messenger, accept orders" +
                    " from the barista, confirm debits/collections and purchases at retail outlets.",
                second_slide: 'The "Warehouse" menu - everything related to warehouse logistics - availability, re-accounting,' +
                    ' posting and even sales from the warehouse. It is possible to generate reports on the movement of goods.',
                three_slide: "Order creation screen - conveniently create and ship orders using the Kavapp Delivery application",
                fourth_slide: '"Points of sale" menu - here the logistician can check the availability of the goods at the ' +
                    'points of sale, make a recalculation at the point and view the movement of the goods',
                fifth_slide: "Delivery settings screen - all settings related to the application in one place. Quickly " +
                    "change the theme of the application, configure security, manage notifications and change the employee profile.",
            },
            head: {
                title: 'Program for control of residues in a coffee shop. Control logistics with Kavapp',
                description: 'Kavapp Delivery is a program and workspace of a logistician. Easy control of balances and movement of goods in the coffee shop. Facilitating the logistician\'s work with Kavapp'
            }
        },
        portfolio_seller: {
            description: '"Kavapp Seller" is a part of the "Kavapp" system, through which all sales from your coffee shop are carried out directly.' +
                ' Convenient and fast sales, the ability to create product orders, cash collection, point purchases, any write-offs' +
                ' and many other functions that will be useful to your employee. Monitor all sales and barista working hours with "Kavapp Seller."',
            post_description: "Kavapp Seller - convenient, fast and reliable.",
            link_apk: {
                description_one: "Alternative download on Android ",
                description_two: ", on Windows ",
                link: "here",
            },
            description_slides: {
                first_slide: "The main screen is the main workplace of the barista in Seller. On this screen, the barista selects" +
                    " the items to be added to the check. If necessary, the barista can use the search to quickly find" +
                    " the desired product. Also he can hold the receipt (by holding the sell button for 2 seconds)",
                second_slide: "Calculation screen. On this screen, the barista indicates the payment method, applies a discount " +
                    "if necessary and calculates the balance to be given to the customer.",
                three_slide: "Functions screen - on this screen the barista can create orders/purchases/charges and collections" +
                    " at the point of sale. Also, the employee can view the availability of goods at the point of sale," +
                    " register a bonus card for the client and view all financial statements for the work shift.",
                fourth_slide: "Sold receipts screen - here the barista can view the entire history of sold receipts for the current " +
                    "work shift, create a return receipt or cancel the last receipt sold.",
                fifth_slide: "Settings screen - in this menu you can view and change all settings related to the Seller " +
                    "application, synchronize data with the admin and connect a receipt printer.",
            },
            head: {
                title: 'A convenient program for automating a coffee shop. Convenient and fast sales',
                description: 'Kavapp Seller - application and workspace for baristas. Intuitive interface for employees, easy to master and work'
            }
        },
        portfolio_admin: {
            description: 'Kavapp Admin is the main part of the Kavapp service. In this workspace, you make all the necessary' +
                ' settings of the system and points of sale, create menus, prices, technical maps. A large number ' +
                'of graphs and reports will help you quickly and accurately analyze your business. In Admin, you ' +
                'also add all your employees, configure the discount system, calculate salaries, and, if necessary,' +
                ' confirm orders/debits/collections, etc.',
            post_description: 'Kavapp Admin is a powerful tool in the hands of an administrator',
            description_slides: {
                first_slide: 'The main page of the personal account - brief statistics on current work shifts, general' +
                    ' information and sales schedules at your retail outlets.',
                second_slide: '"Points of sale" menu - create your points of sale, set the menu, prices, wages and functionality at each point.',
                three_slide: '"Employees" menu - on this screen, you add staff working at your points of sale through the' +
                    ' Kavapp Seller program and specify their wages - you can specify different wages for each employee separately.',
                fourth_slide: '"Logistics" menu - the screen on which you add your logisticians who will work in the Kavapp ' +
                    'Delivery application (here you can get the logistician\'s connection code).',
                fifth_slide: 'The "Ingredients" menu is the screen on which you create all the ingredients, from which ' +
                    'the technical cards of your drinks and products are then formed.',
                sixth_slide: 'The "Drinks" menu is a menu of all drinks sold at your outlet. Here you choose the dishes used' +
                    ' for cooking, specify the price of the drink and the ingredients included in it.',
                seventh_slide: 'The "Products" menu is the screen where you create all the products that will be sold at your' +
                    ' points of sale (these items consist of ingredients that are written off at the time of sale).',
                eighth_slide: 'The "Goods" menu is the goods sold at your points of sale, the goods can be either by piece' +
                    ' or by weight (these items do not consist of ingredients).',
                ninth_slide: 'The "Recalculation" menu is a screen on which you can carry out a recalculation at the point' +
                    ' of sale, indicating the actual indicators of the availability of goods, ingredients and dishes.',
                tenth_slide: 'The "Discounts" menu is a screen where you create all types of discounts that can be used for' +
                    ' the personal cards of your regular customers.',
            },
            head: {
                title: 'The workspace of the coffee shop administrator. CRM for coffee shop automation',
                description: 'Kavapp Admin - the workspace of the administrator. Automation of coffee shop management: technological maps, menus, calculation of wages, balances'
            }
        },
    },
    tariffs: {
        banner: {
            main_title: {
                strong: "The best prices ",
                light: "for the coffee shop management software system",
            },
            secondary_title: {
                strong: "From",
                currency: "UAH/month",
                currency_usd: "USD/month",
                for_shop: "for a coffee shop"
            },
        },

        card_title_currency: {
            uk: "UAH/month",
            usd: "USD/month",
        },
        card_title_every_point: "for every point of sale",
        button: {
            open_button: "Read more",
            close_button: "Turn",
        },
        priceSelect: "Price when paying for: ",
        period: {
            "1": "1 month",
            "3": "3 months",
            "6": "6 months",
            "12": "12 months",
        },
        head: {
            title: 'Tariffs for the Kavapp coffee shop automation system',
            description: 'Prices for CRM for coffee shop automation. Inexpensive program for coffee shop automation. The best prices for the coffee shop management software system'
        }
    },
    contact: {
        left_block: {
            title: "Contact us",
            main_description: "If you have urgent questions or problems in working with the program, you can contact" +
                " us by:",
            phone: "Phone:",
            email: "Email:",
            work_schedule: "Work schedule:",
        },
        right_block: {
            title: "To write a letter",
            name: "Name",
            mail: "Phone number or email",
            mail_usd: "Email",
            mail_mini: "Phone or e-mail",
            mail_mini_usd: "E-mail",
            message: "Your question or message",
            error_message: "No data specified",
            error_contact: "No allowable data specified",
            sent_message: "Your message has been sent!",
            no_message_sent: "Your message has not been sent! Try sending again!",
        },
        head: {
            title: 'CRM Kavapp - contact us for coffee shop automation',
            description: 'Connect the Kavapp program - contact us for calculation and connection. Coffee shop automation is simple and inexpensive with Kavapp'
        }
    },
}

export default pages

export const tariff_list = [
    "Unlimited number of technology cards",
    "Salary, advances, fines",
    "Warehouse and Logistics",
    "Security Settings",
    "Built-in messenger",
    "Pre-cooking",
    "Discount system",
    "Promotions",
    "Cost calculations",
    "Drink Modifiers",
    "Sale from warehouse",
    "Different prices at coffee shops"
]

export const add_tariff_list = [
    "Basic reporting",
    "Full reporting",
    "PRRO"
]