import {useTranslation} from "react-i18next";
import {useContext} from "react";

import "./TariffBanner.scss"
import {GlobalContext} from "../../../../app/providers/ContextProvider";


function TariffsBanner() {
    const {t} = useTranslation()
    const {globalState: {country, prices}} = useContext(GlobalContext)

    return (
        <div className="main-bg">
            <div className="title-block">
                <p className="strong">{t("pages.tariffs.banner.main_title.strong")}</p>
                <p className="main-title">{t("pages.tariffs.banner.main_title.light")}</p>
            </div>
            <div className="triangle-bg gradient"/>
            <div className="secondary-bg gradient">
                {
                    prices.amountYearS &&
                    <div className="secondary-title">
                        <span className={'cost'}>{t("pages.tariffs.banner.secondary_title.strong")}&nbsp;{prices.amountYearS}&nbsp;</span>
                        {
                            country === 'UA' ?
                                <span className={'currency'}>{t("pages.tariffs.banner.secondary_title.currency")}</span>
                                :
                                <span>{t("pages.tariffs.banner.secondary_title.currency_usd")}</span>
                        }
                        <span><br/>{t("pages.tariffs.banner.secondary_title.for_shop")}</span>
                    </div>
                }
            </div>
        </div>
    )
}

export default TariffsBanner;