import {useContext, useLayoutEffect, useState} from 'react';
import {Routes, useLocation} from 'react-router-dom';
import {useTranslation} from "react-i18next";

import Header from "../components/pageComponents/Header/Header"
import Footer from "../components/pageComponents/Footer/Footer";
import Preloader from "../components/pageComponents/Preloader/Preloader";
import HelmetComponent from "../components/pageComponents/HelmetComponent/HelmetComponent";

import {ScrollToTop} from "../utils/ScrollUp";
import {getInitialLocalSettings} from "../utils/utils";
import {GlobalContext} from "./providers/ContextProvider";
import getRoutesList from "../routes/getRoutesList";
import {availableLanguages} from "../utils/i18n";
import './app.scss';

function App() {
    const [isInitialized, setIsInitialized] = useState(false)
    const {globalState, setGlobalState} = useContext(GlobalContext)
    const {i18n} = useTranslation()
    const {pathname} = useLocation();

    useLayoutEffect(() => {
        // Отримуємо з беку місцезнаходження
        getInitialLocalSettings()
            .then((settings) => {
                setGlobalState({...globalState, country: settings.country, prices: settings.prices})
                setTimeout(() => {
                    // Перезаписуємо мову, якщо в pathname вона інша
                    const ifChangeLngFromPathname = availableLanguages.some(langKey => {
                        if (pathname.includes(`/${langKey}/`)) {
                            i18n.changeLanguage(langKey)
                                .then(() => {
                                    document.documentElement.lang = langKey
                                    setIsInitialized(true)
                                })
                            return true
                        }
                        return false
                    })

                    if (!ifChangeLngFromPathname) {
                        if (i18n.language) document.documentElement.lang = i18n.language
                        setIsInitialized(true)
                    }
                }, 0)
            })

        const resizeListener = window.addEventListener("resize", (event) => {
            setGlobalState(prevState => ({...prevState, windowIsSmall: event.target.innerWidth < 760}))
        })
        return (() => window.removeEventListener("resize", resizeListener))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        isInitialized
            ?
            <div className={`app ${globalState.theme}`}>
                <ScrollToTop/>
                <HelmetComponent/>
                <Header/>
                <div className="content container">
                    <Routes>
                        {getRoutesList(i18n.language)}
                    </Routes>
                </div>
                <Footer/>
            </div>
            : <Preloader/>
    );
}

export default App;
