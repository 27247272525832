import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

import ButtonClose from "../../../Buttons/ButtonClose/ButtonClose";
import SocialNetworks from "../../../SocialNetworks/SocialNetworks";
import LanguageButtons from "../LanguageButtons/LanguageButtons";
import DialogOverlay from "../../../DialogOverlay/DialogOverlay";

import './MobileNavigationMenu.scss'
import {ClickAwayListener} from "@mui/material";

const MobileNavigationMenu = (props) => {
    const {onClose} = props
    const {t, i18n} = useTranslation()

    return (
        <DialogOverlay>
            <ClickAwayListener onClickAway={onClose}>
                <nav className={'nav-menu-mobile'}>
                    <ButtonClose onClose={onClose}/>

                    <nav className={"nav-list"} onClick={onClose}>
                        <NavLink to={`/${i18n.language}/about_me`}
                                 className={({isActive}) => isActive ? "nav-list-item active" : "nav-list-item"}>
                            {t('words.about_us')}
                        </NavLink>

                        <NavLink to={`/${i18n.language}/portfolio`}
                                 className={({isActive}) => isActive ? "nav-list-item active" : "nav-list-item"}>
                            {t('words.portfolio')}
                        </NavLink>
                        <NavLink to={`/${i18n.language}/tariffs`}
                                 className={({isActive}) => isActive ? "nav-list-item active" : "nav-list-item"}>
                            {t('words.tariffs')}
                        </NavLink>
                        <NavLink to={`/${i18n.language}/contact`}
                                 className={({isActive}) => isActive ? "nav-list-item active" : "nav-list-item"}>
                            {t('words.contact')}
                        </NavLink>
                    </nav>

                    <SocialNetworks onClick={onClose}/>
                    <LanguageButtons/>
                </nav>
            </ClickAwayListener>
        </DialogOverlay>
    );
};

export default MobileNavigationMenu;