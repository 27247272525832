import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";

import App from './app/App';
import ContextProvider from "./app/providers/ContextProvider";

import './index.css';
import i18n from './utils/i18n'
import {I18nextProvider} from "react-i18next";
import Preloader from "./components/pageComponents/Preloader/Preloader";
import {Suspense} from "react";


ReactDOM.render(
    <BrowserRouter>
        <I18nextProvider i18n={i18n}>
            <ContextProvider>
                <Suspense fallback={<Preloader/>}>
                    <App/>
                </Suspense>
            </ContextProvider>
        </I18nextProvider>
    </BrowserRouter>,
    document.getElementById('root')
);
