import {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";

import viber from '../../../img/icons/viber.svg'
import telegram from '../../../img/icons/telegram.svg'
import whatsapp from '../../../img/icons/whatsapp.svg'
import "./contact.scss"
import {GlobalContext} from "../../../app/providers/ContextProvider";


const isEmpty = (value) => {
    return value.trim().length === 0
}

const isNotValidContact = (value, country) => {
    const emailRegexPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    const phoneRegexPattern = /\d{9,}/

    if (value.trim().length === 0) return true
    if (value.includes('@')) return !emailRegexPattern.test(value);
    // Валідація номеру по телефону
    if (!value.includes('@') && country === 'UA') return !phoneRegexPattern.test(value);
    return true
}

const validations = {
    "name": isEmpty,
    "message": isEmpty,
    "contact": isNotValidContact
}


function Contact() {
    const {t} = useTranslation()
    const {globalState: {windowIsSmall, country}} = useContext(GlobalContext)

    const [valuesField, setValuesField] = useState({name: "", contact: "", message: ""})
    const [errorField, setErrorField] = useState({name: false, contact: false, message: false})
    const [notificationOfSendingForm, setNotificationOfSendingForm] = useState("")
    const [classNameNotification, setClassNameNotification] = useState(true)

    function getFromData() {  //Функія яка формує дані для надсилання через axios
        const bodyFormData = new FormData();
        const fieldName = {...valuesField}
        for (let key in fieldName) {
            bodyFormData.append(key, fieldName[key])
        }
        return bodyFormData
    }


    function sendingForm(event) {
        event.preventDefault()

        const isError = checkFieldsEmptiness();

        if (!isError) {
            axios({
                method: "post",
                url: "/php/callback.php",
                data: getFromData(),
                headers: {"Content-Type": "multipart/form-data"},
            })
                .then(function (response) {
                    setValuesField({name: "", contact: "", message: "",})
                    setNotificationOfSendingForm(true)
                    setClassNameNotification(false)
                    window.gtag('event', 'send_form');
                    window.fbq('track', 'Lead');
                }).catch(function (response) {
                setNotificationOfSendingForm(false)
                setClassNameNotification(false)
            });
        }
    }


    function checkFieldsEmptiness() {
        let isError = false
        let localStateError = {...errorField}
        for (let key in valuesField) {
            if (validations[key](valuesField[key], country)) {
                isError = true;
                localStateError[key] = true;
            } else if (!validations[key](valuesField[key], country)) {
                localStateError[key] = false
            }
        }
        setErrorField({...localStateError})
        return isError
    }


    function onInputValueChange(value, key) {
        setValuesField({...valuesField, [key]: value})
    }

    return (
        <div className="contact">
            <h2>{t("words.contact")}</h2>
            <div className="contact-block gradient">
                <div className="left-block">
                    <h2 className="left-title">{t("pages.contact.left_block.title")}</h2>
                    <section className="social-networks">
                        <p className="main-description">{t("pages.contact.left_block.main_description")}</p>
                        {
                            country === 'UA' &&
                            <div className="section-item">
                                <p className={'left-text'}>{t("pages.contact.left_block.phone")}</p>
                                <a href="tel:+380730528277" onClick={() => {
                                    window.gtag('event', 'click_contact');
                                    window.fbq('track', 'Contact');
                                }}>+38(073)052-82-77</a>
                            </div>
                        }
                        <div className="section-item">
                            <p className={'left-text'}>{t("pages.contact.left_block.email")}</p>
                            <a href="mailto:info@kavapp.com" onClick={() => {
                                window.gtag('event', 'click_contact');
                                window.fbq('track', 'Contact');
                            }}>info@kavapp.com</a>
                        </div>

                        {
                            country === 'UA' &&
                            <div className="section-item">
                                <p className={'left-text'}>{t("pages.contact.left_block.work_schedule")}</p>
                                <p>{t("components.footer.phone.working_days")} 08:00-17:00</p>
                            </div>
                        }
                        <div className="messenger">
                            <a className={'contact-social-icon'} href="https://wa.me/380637534909"
                               target="_blank" onClick={() => {
                                window.gtag('event', 'click_contact');
                                window.fbq('track', 'Contact');
                            }} rel="noreferrer">
                                <p><img src={whatsapp} alt="viber"/><span className={'whatsapp'}>WhatsApp</span></p>
                            </a>
                            <a className={'contact-social-icon'} href="viber://chat?number=%2B380637534909"
                               target="_blank" onClick={() => {
                                window.gtag('event', 'click_contact');
                                window.fbq('track', 'Contact');
                            }} rel="noreferrer">
                                <p><img src={viber} alt="viber"/><span className={'viber'}>Viber</span></p>
                            </a>
                            <a className={'contact-social-icon'} href="https://t.me/kavapp" target="_blank"
                               onClick={() => {
                                   window.gtag('event', 'click_contact');
                                   window.fbq('track', 'Contact');
                               }} rel="noreferrer">
                                <p><img src={telegram} alt="telegram"/><span className={'telegram'}>Telegram</span></p>
                            </a>
                        </div>
                    </section>
                </div>
                <div className="contact-form">
                    <h2 className="right-title">{t("pages.contact.right_block.title")}</h2>
                    <form>
                        <div className="field-section">
                            <div className="white-bg">
                                <input className={errorField.name ? "error" : ""}
                                       value={valuesField.name}
                                       onChange={event => onInputValueChange(event.target.value, "name")}
                                       type="text"
                                       placeholder={t("pages.contact.right_block.name")}
                                />
                            </div>
                            <p className={errorField.name ? "description-error" : "description"}>
                                {t("pages.contact.right_block.error_message")}
                            </p>
                        </div>
                        <div className="field-section">
                            <div className="white-bg">
                                <input className={errorField.contact ? "error" : ""}
                                       value={valuesField.contact}
                                       onChange={event => onInputValueChange(event.target.value, "contact")}
                                       type="text"
                                       placeholder={
                                           !windowIsSmall ?
                                               (country === 'UA' ? t("pages.contact.right_block.mail") : t("pages.contact.right_block.mail_usd"))
                                               :
                                               (country === 'UA' ? t("pages.contact.right_block.mail_mini") : t("pages.contact.right_block.mail_mini_usd"))
                                       }
                                />
                            </div>
                            <p className={errorField.contact ? "description-error" : "description"}>
                                {t("pages.contact.right_block.error_contact")}
                            </p>
                        </div>
                        <div className="field-section">
                            <div className="white-bg-textarea">
                  <textarea className={errorField.message ? "error" : ""}
                            value={valuesField.message}
                            onChange={event => onInputValueChange(event.target.value, "message")}
                            rows={6}
                            placeholder={t("pages.contact.right_block.message")}
                  />
                            </div>
                            <p className={errorField.message ? "description-error" : "description"}>
                                {t("pages.contact.right_block.error_message")}
                            </p>
                        </div>
                        <button onClick={event => sendingForm(event)}>{t("words.send")}</button>
                        <p className={classNameNotification ? "text-hidden" : (notificationOfSendingForm ? "green-text" : "red-text")}>
                            {notificationOfSendingForm ? t("pages.contact.right_block.sent_message") : t("pages.contact.right_block.no_message_sent")}
                        </p>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact


