import Select from 'react-select'
import {useContext, useState} from "react";

import TariffsBanner from "./TariffsBanner/TariffBanner";
import TariffList from "./TariffList/TariffList";

import {useTranslation} from "react-i18next";
import {useAmount} from "./useAmount";


import "./tariffs.scss"
import {GlobalContext} from "../../../app/providers/ContextProvider";


function Tariffs() {
    const {t} = useTranslation()
    const {globalState: {prices}} = useContext(GlobalContext)
    const [togglePriceByPeriod, setTogglePriceByPeriod] = useState('Year')

    const amount = useAmount(togglePriceByPeriod ? togglePriceByPeriod : 'Year', prices)

    function onChange(newValue) {
        setTogglePriceByPeriod(newValue.value)
    }

    const getValue = () => {
        return togglePriceByPeriod ? periodOptions.find(option => option.value === togglePriceByPeriod) : undefined
    }

    const periodOptions = [
        {
            value: "Year",
            label: t("pages.tariffs.period.12")
        },
        {
            value: "HalfYear",
            label: t("pages.tariffs.period.6")
        },
        {
            value: "ThreeMonth",
            label: t("pages.tariffs.period.3")
        },
        {
            value: "Month",
            label: t("pages.tariffs.period.1")
        }
    ]

    return (
        <div className="tariffs">
            <TariffsBanner/>
            <h2>{t('words.tariffs')}</h2>

            <div className={"tariffs-select-wrp"}>
                <span className="text">{t('pages.tariffs.priceSelect')}</span>
                <Select
                    options={periodOptions}
                    value={getValue()}
                    onChange={onChange}
                    isSearchable={false}
                    classNamePrefix={'tariff-select'}
                />
            </div>
            <div className="tariff-cards">
                <TariffList amount={amount}/>
            </div>
        </div>
    )
}

export default Tariffs