export const pages = {
    about_us: {
        banner: "Asistent personal pentru proprietarul cafenelei!",
        description_first: "Kavapp – un sistem multifuncțional și ușor de înțeles pentru automatizarea cafenelelor, minicafenelelor și autocafenelelor.",
        description_second: "Sistemul nostru vă oferă posibilitatea de a configura și organiza procesul de lucru în afacerea dvs. la un preț accesibil.",
        subtitle: "Kavapp – ușor, accesibil, avantajos!",
        advantages: {
            low_price: "Preț redus în comparație cu alte servicii. Posibilitatea de a alege tariful optim pentru tipul dvs. de unitate.",
            low_price_mini: "Preț redus",
            pppo: "Lucrul cu pRRO - deschideți schimbările fiscale și fiscalizați bonurile vândute direct de pe dispozitivul de lucru, conform legislației în vigoare.",
            pppo_mini: "Suport pRRO",
            tech_card: "Crearea de fișe tehnologice într-un număr nelimitat",
            tech_card_mini: "Crearea fișelor tehnice",
            wage: "Calculul salariilor, aplicarea amenzilor, bonusurilor, avansurilor",
            wage_mini: "Calculul salariilor",
            control: "Controlul activității baristului, logisticianului și a fluxului de mărfuri în depozit",
            control_mini: "Controlul activității cafenelei",
            rediscount: "Posibilitatea de a efectua inventarieri la punctele de vânzare și în depozit",
            rediscount_mini: "Funcția de inventar",
            sale_from_warehouse: "Funcția de vânzare a produselor din depozit",
            sale_from_warehouse_mini: "Vânzare din depozit",
            charts: "Un număr mare de grafice și rapoarte pentru controlul afacerii dvs.",
            charts_mini: "Un număr mare de grafice",
            messenger: "Messenger - comunicare internă între barista, logisticieni și administratori în timpul lucrului, fără a se distrage de la procesul de lucru",
            messenger_mini: "Messenger",
            cooking: "Pregătirea prealabilă a mâncărurilor/pozițiilor din meniu/ingredientelor conform fișei tehnologice, pentru vânzări ulterioare sau utilizare la punctul de vânzare",
            cooking_mini: "Prepararea mâncărurilor conform fișei tehnice",
            device: "Compatibilitate ridicată a programului cu diverse dispozitive - sistemul Kavapp acceptă dispozitive cu sistemele de operare Android, iOS și Windows",
            device_mini: "Funcționează pe Android, iOS, Windows",
        },
        categories: {
            title: "Sistemul este împărțit în 3 spații de lucru",
            admin_title: "- Cont personal (Admin)",
            admin_description: " - spațiul de lucru al administratorului",
            seller_title: "- Program de vânzare (Seller)",
            seller_description: " - spațiul de lucru al baristului",
            delivery_title: "- Program logistic, depozit și livrare (Delivery)",
            delivery_description: " - spațiul de lucru al logisticianului",
        },
        head: {
            title: 'Kavapp – sistem pentru automatizarea cafenelei. Sistem CRM pentru cafenele',
            description: 'Automatizarea cafenelelor cu Kavapp. Sistem pentru automatizarea cafenelelor, minicafenelelor și autocafenelelor. Conectați sistemul pentru automatizarea cafenelei online'
        }
    },
    portfolio: {
        portfolio_main: {
            seller: "Program pentru barista",
            delivery: "Program pentru logistician",
            admin: "Program pentru administrator",
            head: {
                title: 'Alege CRM pentru automatizarea cafenelei. Kavapp - automatizează cafeneaua',
                description: 'Implementarea unui sistem CRM pentru cafenea. Cum să alegeți un sistem pentru automatizarea cafenelei. Conectarea programului pentru automatizarea cafenelei. Suport pentru software-ul RRO'
            },
        },
        portfolio_delivery: {
            description: "Kavapp Delivery - aplicație creată special pentru a facilita și controla activitatea logisticianului dvs. Kavapp Delivery este destinată controlului mișcării mărfurilor de la depozit la cafenea, precum și pentru tot ceea ce ține de logistica de depozit. În cadrul întregului sistem, logistica primește mărfurile în depozit, iar cu ajutorul aplicației, angajatul înregistrează cantitatea de mărfuri livrate la cafenea, știe cât din acestea au fost utilizate pentru prepararea băuturilor - și astfel poate vedea cantitatea de mărfuri rămase în cafenea în timp real prin intermediul aplicației Kavapp Delivery (funcționalitate similară este disponibilă și în spațiul de lucru al administratorului).",
            post_description: "Kavapp Delivery - logistica afacerii tale.",
            description_slides: {
                first_slide: "Pagina principală a aplicației Delivery - pe această pagină, logisticianul poate utiliza mesageria, primi comenzi de la barista, confirma deducerile/încasările și achizițiile de la punctele de vânzare.",
                second_slide: 'Meniul "Depozit" - tot ce ține de logistica depozitului - inventar, recepționarea mărfurilor și chiar vânzările din depozit. Este disponibilă opțiunea de generare a rapoartelor privind mișcarea mărfurilor.',
                three_slide: "Ecranul de formare a comenzilor - formați și livrați cu ușurință comenzile folosind aplicația Kavapp Delivery.",
                fourth_slide: 'Meniul "Puncte de vânzare" - aici, logisticianul poate verifica disponibilitatea mărfurilor la punctele de vânzare, realiza inventar și vizualiza mișcarea mărfurilor.',
                fifth_slide: "Ecranul setărilor Delivery - toate setările aplicației într-un singur loc. Schimbare rapidă a temei aplicației, setări de securitate, gestionarea notificărilor și schimbarea profilului angajatului.",
            },
            head: {
                title: 'Aplicația de control al stocurilor în cafenea. Controlează logistica cu Kavapp',
                description: 'Kavapp Delivery - aplicația și spațiul de lucru al logisticianului. Control facil al stocurilor și mișcării mărfurilor în cafenea. Simplifică activitatea logisticianului cu Kavapp.'
            }
        },
        portfolio_seller: {
            description: "Kavapp Seller - partea sistemului Kavapp prin care se realizează direct toate vânzările din cafeneaua dvs. Vânzări rapide și convenabile, posibilitatea de a crea comenzi de mărfuri, încasarea numerarului, achiziții de mărfuri la punct, orice deduceri și multe alte funcționalități utile angajaților dvs. Controlați toate vânzările și orele de lucru ale barista cu ajutorul aplicației Kavapp Seller.",
            post_description: "Kavapp Seller - convenabil, rapid și sigur.",
            link_apk: {
                description_one: "Descărcare alternativă pentru Android ",
                description_two: ", pentru Windows ",
                link: "aici",
            },
            description_slides: {
                first_slide: "Ecranul principal - locul de muncă principal al barista în aplicația Seller. Pe acest ecran, barista selectează pozițiile care trebuie adăugate în bon. Dacă este necesar, barista poate utiliza căutarea pentru a găsi rapid produsul dorit și poate suspenda bonul (menținând apăsat butonul de vânzare timp de 2 secunde).",
                second_slide: "Ecranul de calcul. Pe acest ecran, barista specifică metoda de plată, aplică reduceri dacă este necesar și calculează restul care trebuie returnat clientului.",
                three_slide: "Ecranul de funcții - pe acest ecran, barista poate crea comenzi/achiziții/deduceri și încasări la punctul de vânzare. De asemenea, angajatul poate verifica disponibilitatea mărfurilor la punctul de vânzare, înregistra carduri de fidelitate pentru clienți și vizualiza toate rapoartele financiare pentru schimbul de lucru.",
                fourth_slide: "Ecranul bonurilor vândute - aici barista poate vizualiza istoricul tuturor bonurilor vândute în schimbul de lucru curent, poate crea un bon de returnare sau anula ultimul bon vândut.",
                fifth_slide: "Ecranul setărilor - în acest meniu puteți vizualiza și modifica toate setările aplicației Seller, sincroniza datele cu panoul de administrare și conecta imprimanta de bonuri.",
            },
            head: {
                title: 'Aplicație convenabilă pentru automatizarea cafenelei. Vânzări rapide și convenabile',
                description: 'Kavapp Seller - aplicația și spațiul de lucru pentru barista. Interfață intuitivă pentru angajați, ușor de învățat și utilizat'
            }
        },
        portfolio_admin: {
            description: 'Kavapp Admin - partea principală a serviciului Kavapp. În acest spațiu de lucru, efectuați toate setările necesare ale sistemului și punctelor de vânzare, creați meniuri, prețuri și fișe tehnice. O mare varietate de grafice și rapoarte vă ajută să analizați rapid și precis afacerea dvs. În Admin, adăugați toți angajații dvs., configurați sistemul de discount, calculați salariile și, dacă este necesar, confirmați comenzi/ajustări/încasări etc.',
            post_description: 'Kavapp Admin - un instrument puternic în mâinile administratorului',
            description_slides: {
                first_slide: 'Pagina principală a contului personal - statistică scurtă despre schimburile de lucru actuale, informații generale și grafice despre vânzările de la punctele dvs. de vânzare.',
                second_slide: 'Meniul "Puncte de vânzare" - creați punctele de vânzare, configurați meniuri, prețuri, salarii și funcționalitate pentru fiecare punct.',
                three_slide: 'Meniul "Angajați" - pe acest ecran, adăugați personalul care lucrează la punctele dvs. de vânzare prin aplicația Kavapp Seller și specificați salariile lor - puteți seta salarii diferite pentru fiecare angajat.',
                fourth_slide: 'Meniul "Logisticieni" - ecranul unde adăugați logisticienii dvs. care vor lucra în aplicația Kavapp Delivery (aici puteți obține codul de conectare a logisticianului).',
                fifth_slide: 'Meniul "Ingrediente" - ecranul unde creați toate ingredientele din care se formează fișele tehnice ale băuturilor și produselor dvs.',
                sixth_slide: 'Meniul "Băuturi" - acesta este meniul tuturor băuturilor care se vând la punctul dvs. de vânzare. Aici selectați recipientul utilizat pentru preparare, specificați prețul băuturii și ingredientele sale.',
                seventh_slide: 'Meniul "Produse" - ecranul unde creați toate produsele care vor fi vândute la punctele dvs. de vânzare (aceste poziții constau din ingrediente deduse la vânzare).',
                eighth_slide: 'Meniul "Mărfuri" - acestea sunt mărfurile care se vând la punctele dvs. de vânzare, care pot fi atât bucăți individuale, cât și mărfuri la kilogram (aceste poziții nu constau din ingrediente).',
                ninth_slide: 'Meniul "Reevaluare" - ecranul unde puteți efectua reevaluări la punctul de vânzare, specificând indicatorii efectivi de disponibilitate a mărfurilor, ingredientelor și recipientelor.',
                tenth_slide: 'Meniul "Discounturi" - ecranul unde creați toate tipurile de reduceri care pot fi utilizate pentru cardurile personalizate ale clienților dvs. fideli.',
            },
            head: {
                title: 'Spațiu de lucru pentru administratorul cafenelei. CRM pentru automatizarea cafenelei',
                description: 'Kavapp Admin - spațiul de lucru al administratorului. Automatizarea gestionării cafenelei: fișe tehnice, meniuri, calcularea salariilor, stocuri'
            }
        },
    },

    tariffs: {
        banner: {
            main_title: {
                strong: "Cele mai avantajoase ",
                light: "prețuri ale sistemului de gestionare a cafenelei",
            },
            secondary_title: {
                strong: "De la",
                currency: "RON/lună",
                currency_usd: "USD/lună",
                for_shop: "pentru o cafenea"
            },
        },
        card_title_currency: {
            uk: "UAH/lună",
            usd: "USD/lună",
        },
        card_title_every_point: "pe punct de vânzare",
        button: {
            open_button: "Mai multe detalii",
            close_button: "Închide",
        },
        priceSelect: "Preț în cazul plății pentru: ",
        period: {
            "1": "1 lună",
            "3": "3 luni",
            "6": "6 luni",
            "12": "12 luni",
        },
        head: {
            title: 'Tarife pentru sistemul de automatizare a cafenelei Kavapp',
            description: 'Prețuri pentru CRM pentru automatizarea cafenelei. Aplicație accesibilă pentru automatizarea cafenelei. Cele mai avantajoase prețuri ale sistemului de gestionare a cafenelei'
        }
    },
    contact: {
        left_block: {
            title: "Contactați-ne",
            main_description: "Dacă aveți întrebări urgente pentru noi sau probleme în utilizarea aplicației, - ne puteți contacta în orice mod convenabil pentru dvs.",
            phone: "Telefon:",
            email: "E-mail:",
            work_schedule: "Program de lucru:",
        },
        right_block: {
            title: "Trimiteți un mesaj",
            name: "Nume",
            mail: "Număr de telefon sau e-mail",
            mail_usd: "E-mail",
            mail_mini: "Telefon sau e-mail",
            mail_mini_usd: "E-mail",
            message: "Întrebarea sau mesajul dvs.",
            error_message: "Datele nu sunt specificate",
            error_contact: "Datele nu sunt valide",
            sent_message: "Mesajul dvs. a fost trimis!",
            no_message_sent: "Mesajul dvs. nu a fost trimis! Încercați să trimiteți din nou!",
        },
        head: {
            title: 'CRM Kavapp - contactați-ne pentru automatizarea cafenelei',
            description: 'Conectați aplicația Kavapp - contactați-ne pentru calcul și conectare. Automatizarea cafenelei este simplă și accesibilă cu Kavapp\n'
        }
    },
}

export default pages

export const tariff_list = [
    "Număr nelimitat de fișe tehnice",
    "Salarii, avansuri, penalizări",
    "Depozit și logistică",
    "Setări de securitate",
    "Mesagerie integrată",
    "Pregătire anticipată",
    "Sistem de discount",
    "Constructor de promoții",
    "Calcularea costurilor de producție",
    "Modificatori de băuturi",
    "Vânzare din depozit",
    "Prețuri diferite la cafenele"
]

export const add_tariff_list = [
    "Raportare de bază",
    "Raportare completă",
    "Cash register electronic (PРRO)",
]