import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import './ButtonClose.scss'

const ButtonClose = (props) => {
    const {onClose} = props

    return (
        <div className={'button-close'}>
            <IconButton
                aria-label="close"
                onClick={onClose}
            >
                <CloseIcon/>
            </IconButton>
        </div>
    );
};

export default ButtonClose;