import React from 'react';

import "./Preloader.scss"

const Preloader = () => {
    return (
        <div className='preloader-wrapper'>
            <div className="lds-ring">
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>

    );
};

export default Preloader;