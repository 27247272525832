import {useEffect, useState} from "react";

const initialState = {
    "2": "",
    "3": "",
    "4": ""
}

const tariffsObj = {
    "S": "2",
    "M": "3",
    "L": "4"
}

const tariffsList = Object.keys(tariffsObj)

export function useAmount(period, tariffs) {
    const [amount, setAmount] = useState(initialState)

    useEffect(() => {
        const amount = {...initialState}

        tariffsList.forEach(item => {
            const tempPrice = tariffs[`amount${period}${item}`]
            if (tempPrice && !isNaN(tempPrice)) {
                amount[tariffsObj[item]] = tempPrice
            } else amount[tariffsObj[item]] = ''
        })
        setAmount(amount)
    }, [period, tariffs])

    return amount
}
