import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

const localsKey = {
    "about_me": 'pages.about_us',
    "portfolio": "pages.portfolio.portfolio_main",
    "portfolio/admin": "pages.portfolio.portfolio_admin",
    "portfolio/sale": "pages.portfolio.portfolio_seller",
    "portfolio/delivery": "pages.portfolio.portfolio_delivery",
    "tariffs": "pages.tariffs",
    "contact": "pages.contact",
}

const URL = 'https://kavapp.com'

function getPathNameWithoutLocale(pathname, language) {
    return pathname.replace(`/${language}/`, '')
}

const HelmetComponent = () => {
    const {t, i18n} = useTranslation()
    const {pathname} = useLocation();
    const [currentLocal, setCurrentLocal] = useState('pages.about_us')
    const [pathNameWithoutLocale, setPathNameWithoutLocale] = useState(getPathNameWithoutLocale(pathname, i18n.language))


    useEffect(() => {
        const pathNameWithoutLocale = getPathNameWithoutLocale(pathname, i18n.language)
        setPathNameWithoutLocale(pathNameWithoutLocale)

        const key = localsKey[pathNameWithoutLocale]
        key
            ? setCurrentLocal(localsKey[pathNameWithoutLocale])
            : setCurrentLocal(localsKey['about_me'])

    }, [pathname])

    return (
        <Helmet>
            <html lang={`${i18n.language}`}/>
            <title>{t(`${currentLocal}.head.title`)}</title>

            <link rel="canonical" href={`${URL}${pathname}`}/>
            <link rel="alternate" href={`${URL}/en/${pathNameWithoutLocale}`} hrefLang="en"/>
            <link rel="alternate" href={`${URL}/es/${pathNameWithoutLocale}`} hrefLang="es"/>
            <link rel="alternate" href={`${URL}/uk/${pathNameWithoutLocale}`} hrefLang="uk"/>
            <link rel="alternate" href={`${URL}/ru/${pathNameWithoutLocale}`} hrefLang="ru"/>

            <meta name="description" content={t(`${currentLocal}.head.description`)}/>
        </Helmet>
    );
};

export default HelmetComponent;