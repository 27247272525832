import React, {useContext} from 'react';
import nightMode from "../../../../img/icons/nightMode.svg";
import lightMode from "../../../../img/icons/lightMode.svg";
import {setTheme} from "../../../../utils/utils";
import {GlobalContext} from "../../../../app/providers/ContextProvider";

const ChangeModeButton = () => {
    const {globalState: {theme}, setGlobalState} = useContext(GlobalContext)

    function switchThemeMode(value) {
        setGlobalState(prevState => ({...prevState, theme: value}))
        setTheme(value)
    }

    return (
        <div className="change-mode-button">
            <img
                onClick={() => switchThemeMode("night")}
                className={theme === "light" ? "light show" : "light hide"}
                src={nightMode}
                alt="#"
            />
            <img
                onClick={() => switchThemeMode("light")}
                className={theme === "night" ? "night show" : "night hide"}
                src={lightMode}
                alt="#"
            />
        </div>
    );
};

export default ChangeModeButton;