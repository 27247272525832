import React from 'react';
import Select from "react-select";

import "./LanguageSelect.scss"
import {useTranslation} from "react-i18next";
import {availableLanguages} from "../../../../../utils/i18n";
import {useLocation, useNavigate} from "react-router-dom";

const options = availableLanguages.map(item => ({value: item, label: item}))

const LanguageSelect = () => {
    const {i18n} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const getValue = () => {
        return i18n.language ? options.find(option => option.value === i18n.language) : undefined
    }

    const onHandlerChange = (language) => {
        const newLanguage = language?.value
        const mewPath = location.pathname.replace(`/${i18n.language}/`, `/${newLanguage}/`)
        i18n.changeLanguage(newLanguage).then(navigate(mewPath))
    }

    return (
        <div className={"language-select-field"}>
            <Select
                options={options}
                value={getValue()}
                onChange={onHandlerChange}
                isSearchable={false}
                classNamePrefix={"language-select"}
                className={"language-select-wrapper"}
                //menuIsOpen={true}
            />
        </div>
    );
};

export default LanguageSelect;
