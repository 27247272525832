import {useLocation} from "react-router-dom";
import {useEffect} from "react";


export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(1, 1);
    }, [pathname]);

    return null;
}