import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

const ButtonToPrivatAccount = ({className, svgIcon}) => {
    const {t, i18n} = useTranslation()
    const language = i18n.language
    const [clientIdRef, setClientIdRef] = useState('')
    const [sessionIdRef, setSessionIdRef] = useState('')

    useEffect(() => {
        window.gtag('get', 'G-KFZ7JDZP1K', 'client_id', (client_id) => {
            setClientIdRef(client_id)
        });
        window.gtag('get', 'G-KFZ7JDZP1K', 'session_id', (session_id) => {
            setSessionIdRef(session_id)
        });
    }, [])

    return (
        <a
            className={className}
            rel="noreferrer"
            target="_blank"
            href={`https://admin.kavapp.com/#/overview?language=${language}&clientId=${clientIdRef}&sessionId=${sessionIdRef}`}
            //href={`http://localhost:9090/#/overview?language=${language}&client_id=${clientIdRef}&session_id=${sessionIdRef}`}
            onClick={() => {
                window.gtag('event', 'click_kabinet');
                window.fbq('track', 'ViewContent');
            }}>
            {svgIcon ? svgIcon : <button>{t("components.header.private_account")}</button>}
        </a>
    );
};

export default ButtonToPrivatAccount;