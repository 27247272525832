import React from 'react';
import {useTranslation} from "react-i18next";

import "./AppBadge.scss"

import googleEn from "../../../img/app_badges/google/g_eng.svg"
import googleRu from "../../../img/app_badges/google/g_ru.svg"
import googleUa from "../../../img/app_badges/google/g_ua.svg"

import appleEn from "../../../img/app_badges/apple/a_eng.svg"
import appleRu from "../../../img/app_badges/apple/a_ru.svg"
import appleUa from "../../../img/app_badges/apple/a_ua.svg"

import microsoftEn from "../../../img/app_badges/microsoft/m_eng.svg"
import microsoftRu from "../../../img/app_badges/microsoft/m_ru.svg"
import microsoftUa from "../../../img/app_badges/microsoft/m_ua.svg"

export const banners = {
    en: {
        google: googleEn,
        apple: appleEn,
        microsoft: microsoftEn
    },
    ru: {
        google: googleRu,
        apple: appleRu,
        microsoft: microsoftRu
    },
    uk: {
        google: googleUa,
        apple: appleUa,
        microsoft: microsoftUa
    },
    es: {}
}


const AppBadge = ({href, badge}) => {
    const {i18n} = useTranslation();
    const language = i18n.language
    const currentBadge = (banners[language] && banners[language][badge]) ? banners[language][badge] : banners.en[badge]

    return (
        currentBadge && href ?
            <a className="link_button" href={href} target="_blank" rel="noreferrer">
                <img src={currentBadge} alt="application_badge"/>
            </a>
            : null
    );
};

export default AppBadge;