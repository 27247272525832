import {useContext} from "react";
import {useTranslation} from "react-i18next";

import SocialNetworks from "../SocialNetworks/SocialNetworks";
import PhoneBlock from "./components/PhoneBlock";
import PaymentBlock from "./components/PaymentBlock";


import "./footer.scss"
import {GlobalContext} from "../../../app/providers/ContextProvider";

function Footer() {
    const {globalState: {windowIsSmall, country}} = useContext(GlobalContext)
    const {t} = useTranslation()
    return (
        <div className="footer light-gradient">
            <div className={country === 'UA' ? "footer-container" : "footer-container footer-container--three-col"}>
                {!windowIsSmall ?
                    <>
                        <SocialNetworks/>
                        <PhoneBlock/>
                        <PaymentBlock/>
                    </>
                    :
                    <>
                        <SocialNetworks/>
                        <PaymentBlock/>
                        <PhoneBlock/>
                    </>
                }
            </div>

            <div className={'footer__application-dev'}>
                {t('components.footer.applicationDeveloper')} -&nbsp;
                <a className={'application-dev__link'} href="https://softwars.com.ua" rel="noopener"
                   target="_blank">SoftWars
                </a>
            </div>
        </div>
    )
}

export default Footer