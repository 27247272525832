import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import {GlobalContext} from "../../../../app/providers/ContextProvider";


const PhoneBlock = () => {
    const {t} = useTranslation()
    const {globalState: {country, windowIsSmall}} = useContext(GlobalContext)

    return (
        country === 'UA' ?
            <div className="phone-block">
                {
                    !windowIsSmall &&
                    <div className="title">{t("components.footer.phone.title")}</div>
                }
                <span className="phone"><a href="tel:+380730528277">+38(073)052-82-77</a></span>
                <span
                    className="work_schedule">({t("components.footer.phone.working_days")} 8:00-17:00)</span>
            </div>
            : null
    );
};

export default PhoneBlock;