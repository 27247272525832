import React from 'react';
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {availableLanguages} from "../../../../../utils/i18n";

import './LanguageButtons.scss'

const LanguageButtons = () => {
    const {t, i18n} = useTranslation()
    const language = i18n.language
    const navigate = useNavigate()
    const location = useLocation()

    const onHandlerChangeLanguage = (value) => {
        const mewPath = location.pathname.replace(`/${language}/`, `/${value}/`)
        i18n.changeLanguage(value).then(navigate(mewPath))
    }

    return (
        <div className="language-change-block">
            {availableLanguages.map((itemLanguage, index) =>
                <span key={itemLanguage}
                      className={language === itemLanguage ? "active languages-block-item" : "languages-block-item"}>
                        <span
                            onClick={() => onHandlerChangeLanguage(itemLanguage)}
                            className="languages-text">{itemLanguage.toUpperCase()}
                        </span>
                    {index < availableLanguages.length - 1 && <span className="line"/>}
                </span>
            )}
        </div>
    );
};

export default LanguageButtons;