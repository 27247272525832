import React from 'react';
import classNames from "classnames";
import Highlighter from "react-highlight-words";

import List from "./List";

import {useContext} from "react";
import {FaqListContext} from "../context/context";
import {Collapse, ListItem, ListItemText} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

export const FaqListItem = (props) => {
    const {item} = props

    const {isOpenItemsInList, onClickOnListItem, searchText} = useContext(FaqListContext)
    const listItemStyles = classNames('list-item', {'open': isOpenItemsInList[item.number]}, {'list-item-text': !!item.text})

    const currentText = item.text ? item.text : `${item.number} ${item.name}`

    return (
        <>
            <ListItem
                key={item.name ? item.name : item.text}
                className={listItemStyles}
                button={!item.text}
                disableRipple={!item.text}
                onClick={() => onClickOnListItem(item)}
            >
                <ListItemText className={'text'}>
                    {
                        (searchText.length > 2 && !item.components) ?
                            <Highlighter
                                searchWords={[searchText]}
                                autoEscape={true}
                                textToHighlight={currentText}
                            />
                            : currentText
                    }
                </ListItemText>
                {
                    (item.children && item.children.length > 0) && (isOpenItemsInList[item.number]
                        ? <ExpandLess/> : <ExpandMore/>)
                }
            </ListItem>
            {(item.children && item.children.length > 0) && (
                <Collapse in={isOpenItemsInList[item.number]}
                          timeout="auto"
                          unmountOnExit
                >
                    <List style={{marginLeft: "20px"}} list={item.children}/>
                </Collapse>
            )}
        </>
    );
};

export default FaqListItem;
