import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";

import visa from "../../../../img/icons/VisaBlue.svg";
import visaWhite from "../../../../img/icons/VisaWhite.svg";
import mastercardLight from "../../../../img/icons/mastercard-light.svg";
import mastercardNight from "../../../../img/icons/mastercard-night.svg";
import {GlobalContext} from "../../../../app/providers/ContextProvider";

const PaymentBlock = () => {
    const {t} = useTranslation()
    const {globalState: {theme}} = useContext(GlobalContext)

    return (
        <div className="payment-block">
            <div className="title">{t("components.footer.we_accept")}</div>
            <div className="payment-list">
                <img src={theme === "light" ? visa : visaWhite} alt="#"/>
                <img src={theme === "light" ? mastercardLight : mastercardNight} alt="#"/>
            </div>
        </div>
    );
};

export default PaymentBlock;