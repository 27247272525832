import React from 'react';
import "./AppBadgeWrapper.scss"

const AppBadgeWrapper = ({children}) => {
    return (
        <div className="links_group">
            {children}
        </div>
    );
};

export default AppBadgeWrapper;