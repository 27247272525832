import axios from "axios";

export async function getInitialLocalSettings() {
    try {
        const {data} = await axios.get('https://api.kavapp.com/tariff')

        return {
            country: data.data.country ? data.data.country : '',
            prices: data.data ? data.data : {}
        }

    } catch (e) {
        console.log(e)
        return {
            country: '',
            prices: {}
        }
    }
}


export function setTheme(theme) {
    window.localStorage.setItem("theme", theme)
}

export function getTheme() {
    const theme = window.localStorage.getItem("theme")

    if (theme) {
        return theme
    } else {
        setTheme('light')
        return 'light'
    }
}