const components = {
    programForLogisticians: "Programa y espacio de trabajo para el logista",
    programForSeller: "Programa y espacio de trabajo para el barista",
    programForAdmin: "Espacio de trabajo del administrador",
    header: {
        private_account: "Cuenta personal",
        admin: "Kavapp Admin (Para el administrador)",
        sale: "Kavapp Seller (Para ventas)",
        delivery: "Kavapp Delivery (Para el logista)",
    },
    footer: {
        connect: "Nuestras redes sociales",
        language_change: "Cambiar idioma",
        we_accept: "Aceptamos ",
        phone: {
            title: "Nuestro teléfono: ",
            working_days: "lun-vie",
        },
        applicationDeveloper: "Desarrollador del sitio y sistema Kavapp"
    },
    system_requirements: {
        android: "Versión mínima para Android - 6 (recomendada 9)",
        ios: "Versión mínima para iOS - 12 (recomendada 15)",
        windows: "Versión mínima para Windows - 10, 64 bits",
    }
}

export default components