import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Admin_img_en from "../../../img/image/KavApp_admin_en.png"
import Seller_img_ua from "../../../img/image/KavApp_seller_ua.png"
import Seller_img_en from "../../../img/image/KavApp_seller_en.png"
import Delivery_img_ua from "../../../img/image/KavApp_dellivery_ua.png"
import Delivery_img_en from "../../../img/image/KavApp_dellivery_en.png"

import "./portfolio.scss"
import {useMemo} from "react";


const imagesS = {
    en: Seller_img_en,
    ua: Seller_img_ua,
}

const imagesD = {
    en: Delivery_img_en,
    ua: Delivery_img_ua,
}

function Portfolio() {
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()

    function onPortfolioItemClick(path) {
        navigate(`/${i18n.language}/portfolio${path}`)
    }

    const slide = useMemo(() => {
        const lng = (i18n.language === 'en' || i18n.language === 'es') ? 'en' : 'ua'

        return {
            seller: imagesS[lng],
            delivery: imagesD[lng]
        }
    }, [i18n.language])

    return (
        <div className="portfolio">
            <h1 className="main-title">{t("words.portfolio")}</h1>
            <div className="portfolio-items">
                <div className="portfolio-item light-gradient" onClick={() => onPortfolioItemClick("/sale")}>
                    <img src={slide.seller} alt="Seller_img" className="first-img"/>
                    <div className="top-layout ">
                        <div className="upper-background gradient"/>
                        <span className="portfolio-item-title">Kavapp Seller</span>
                        <p>{t("pages.portfolio.portfolio_main.seller")}</p>
                    </div>
                </div>
                <div className="portfolio-item light-gradient" onClick={() => onPortfolioItemClick("/delivery")}>
                    <img src={slide.delivery} alt="Delivery_img" className="second-img"/>
                    <div className="top-layout ">
                        <div className="upper-background gradient"/>
                        <span className="portfolio-item-title">Kavapp Delivery</span>
                        <p>{t("pages.portfolio.portfolio_main.delivery")}</p>
                    </div>
                </div>
                <div className="portfolio-item light-gradient" onClick={() => onPortfolioItemClick("/admin")}>
                    <img src={Admin_img_en} alt="Admin_img" className="three-img"/>
                    <div className="top-layout">
                        <div className="upper-background gradient"/>
                        <span className="portfolio-item-title">Kavapp Admin</span>
                        <p>{t("pages.portfolio.portfolio_main.admin")}</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Portfolio