import {useEffect} from "react";

import './DialogOverlay.scss'

const DialogOverlay = (props) => {
    const {close = Function.prototype, children} = props

    useEffect(() => {
        document.body.style.overflow = 'hidden'

        return () => {
            document.body.style.overflow = ''
        }
    }, [])

    return (
        <div onClick={close} className={'dialog-overlay'}>
            {children}
        </div>
    );
};

export default DialogOverlay;