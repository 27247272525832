import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {GlobalContext} from "../../../../app/providers/ContextProvider";


const Price = ({price, currency}) => {
    const {t} = useTranslation()
    return (
        <span className="price custom">
            <span>
                <strong>{price}</strong>&nbsp;{currency}
            </span>
            <span>{t('pages.tariffs.card_title_every_point')}</span>
        </span>
    )
}

const Button = ({tariffId, language}) => {
    const {t} = useTranslation()
    return (
        <a href={`https://admin.kavapp.com/#/registration?key=${tariffId}&language=${language}`}
           target="_blank" onClick={() => {
            window.gtag('event', 'view_registration');
            window.fbq('track', 'ViewContent');
        }} rel="noreferrer">
            <button className="button">{t("words.select")}</button>
        </a>
    )
}

const TariffItem = ({currentTariff, currency}) => {
    const {t} = useTranslation()
    const {i18n} = useTranslation();
    const language = i18n.language
    const {globalState: {windowIsSmall}} = useContext(GlobalContext)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const closeDropdown = () => setIsDropdownOpen(false)

    useEffect(() => {
        window.addEventListener("orientationchange", closeDropdown)
        return () => window.removeEventListener("orientationchange", closeDropdown)
    }, [])

    return (
        <div className="tariff-card light-gradient">
            <span className="title">{currentTariff.name}</span>
            <Price price={currentTariff.price} currency={currency}/>
            {
                windowIsSmall &&
                <input type="checkbox" className="read-more-checker" id={`read-more-checker-${currentTariff.tariffId}`}
                       value={!isDropdownOpen}
                       onChange={() => setIsDropdownOpen(isDropdownOpen)}
                />
            }
            <ul className="characteristics-list">
                {
                    currentTariff.include.map((item, index) =>
                        <li className="characteristics-item true" key={`${item + index}`}>{item}</li>)
                }
                {
                    currentTariff.notInclude.map((item, index) =>
                        <li className="characteristics-item false" key={`${item + index}`}>{item}</li>)
                }
            </ul>

            {
                !windowIsSmall ?
                    <Button tariffId={currentTariff.tariffId} language={language}/>
                    :
                    <div className="buttons-wrapper">
                        <label htmlFor={`read-more-checker-${currentTariff.tariffId}`} className="read-more-button"
                               onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                            {isDropdownOpen ? t("pages.tariffs.button.close_button") : t("pages.tariffs.button.open_button")}
                        </label>
                        <Button tariffId={currentTariff.tariffId} language={language}/>
                    </div>
            }
        </div>
    );
};

export default TariffItem;