import i18n from 'i18next'
import {initReactI18next} from "react-i18next"
import LanguageDetector from 'i18next-browser-languagedetector'
import getIsCountryUkraineDetector from "./isCountryUkraine";

import en from "../locales/en/translation"
import es from "../locales/es/translation"
import uk from "../locales/uk/translation"
import ru from "../locales/ru/translation"
import ro from "../locales/ro/translation"

const resources = {en, es, uk, ru, ro}
const defaultLanguage = 'en'
// Доступні мови. !!! Контролювати порядок розміщення в масиві, з масиву формується список мов у футорі.
export const availableLanguages = ["en", "es", "uk", "ru", "ro"];

// TODO Переробити, пріорітет мови брати із pathname /en/..., важливо для SEO
getIsCountryUkraineDetector()
    .then((data) => {
        const languageDetector = new LanguageDetector()
        languageDetector.addDetector(data)

        i18n
            .use(languageDetector)
            .use(initReactI18next)
            .init({
                resources,
                react: {useSuspense: false},
                fallbackLng: defaultLanguage,
                supportedLngs: availableLanguages,
                detection: {
                    order: ['localStorage', 'isCountryUkraine', 'navigator'],
                    caches: ['localStorage']
                },
                interpolation: {
                    escapeValue: false
                },
                debug: process.env.NODE_ENV === 'development'
            });
    })

export default i18n