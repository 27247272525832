import words from "./modules/words";
import components from "./modules/components";
import pages, {tariff_list} from "./modules/pages";
import {add_tariff_list} from "./modules/pages";
import {faq} from "./modules/faq";

const translation = {
    translation: {
        words,
        components,
        pages,
        tariff_list,
        add_tariff_list,
        faq

    }
}

export default translation