import React from 'react';
import {useTranslation} from "react-i18next";

import facebook from "../../../img/icons/facebook.svg";
import instagram from "../../../img/icons/instagram.svg";
import telegram from "../../../img/icons/telegram.svg";
import './SocialNetworks.scss'

const SocialNetworks = (props) => {
    const {onClick = Function.prototype} = props
    const {t} = useTranslation()

    return (
        <div className="social-networks" onClick={onClick}>
            <span className="title">{t("components.footer.connect")}</span>
            <div className="social-networks-list">
                <a href="https://www.facebook.com/kavappua" target="_blank" rel="noreferrer"
                   onClick={() => {
                       window.gtag('event', 'click_contact');
                       window.fbq('track', 'Contact')
                   }}>
                    <img src={facebook} alt="#"/>
                </a>
                <a href="https://www.instagram.com/kavapp/" target="_blank" rel="noreferrer"
                   onClick={() => {
                       window.gtag('event', 'click_contact');
                       window.fbq('track', 'Contact')
                   }}>
                    <img src={instagram} alt="#"/>
                </a>
                <a href="https://t.me/kavappchannel" target="_blank" rel="noreferrer" onClick={() => {
                    window.gtag('event', 'click_contact');
                    window.fbq('track', 'Contact')
                }}>
                    <img src={telegram} alt="#"/>
                </a>
            </div>
        </div>
    );
};

export default SocialNetworks;