import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";

import TariffItem from "./TariffItem";
import {GlobalContext} from "../../../../app/providers/ContextProvider";


const TariffList = ({amount}) => {
    const {t} = useTranslation()
    const {globalState: {country}} = useContext(GlobalContext)

    // Валюта - доллар або грн.
    const currency = country === 'UA' ? t('pages.tariffs.card_title_currency.uk') : t('pages.tariffs.card_title_currency.usd')

    // Опис тарифів
    const list = t("tariff_list", {returnObjects: true})
    const addList = t("add_tariff_list", {returnObjects: true})

    const tariffGroup = [
        {
            tariffId: "2",
            name: 'S',
            price: amount["2"],
            include: [list[0], addList[0], list[1], list[2], list[3],],
            notInclude: [list[4], list[5], list[6], list[7], list[8], list[9], list[10], list[11]]
        },
        {
            tariffId: "3",
            name: 'M',
            price: amount["3"],
            include: [list[0], addList[1], list[1], list[2], list[3], list[4], list[5], list[6], list[7], list[8]],
            notInclude: [list[9], list[10], list[11]]
        },
        {
            tariffId: "4",
            name: 'L',
            price: amount["4"],
            include: [list[0], addList[1], list[1], list[2], list[3], list[4], list[5], list[6], list[7], list[8], list[9], list[10], list[11]],
            notInclude: []
        }
    ]

    // Додаємо ПРРО
    if (country === 'UA') {
        tariffGroup.forEach(item => item.include.splice(4, 0, addList[2]))
    }

    return (
        tariffGroup.map(item => <TariffItem currentTariff={item} key={item.tariffId} currency={currency}/>)
    );
};

export default TariffList;