import axios from "axios";

let hasLocalStorageSupport = null;
const localStorageAvailable = () => {
    if (hasLocalStorageSupport !== null) return hasLocalStorageSupport;

    try {
        hasLocalStorageSupport = window !== 'undefined' && window.localStorage !== null;
        const testKey = 'i18next.translate.boo';
        window.localStorage.setItem(testKey, 'foo');
        window.localStorage.removeItem(testKey);
    } catch (e) {
        hasLocalStorageSupport = false;
    }
    return hasLocalStorageSupport;
}

export default async () => {
    let found
    try {
        const {data} = await axios.get('https://api.kavapp.com/tariff')
        if (data.data.country === 'UA') found = 'uk'
    } catch (e) {
        console.log(e)
    }

    return {
        name: 'isCountryUkraine',

        lookup(options) {
            return found
        },

        cacheUserLanguage(lng, options) {
            if (options.lookupLocalStorage && localStorageAvailable()) {
                window.localStorage.setItem(options.lookupLocalStorage, lng);
            }
        },
    }
}
