import {useTranslation} from "react-i18next";
import {useMemo} from "react";

import SliderForPortfolio from "../../../pageComponents/SliderForPortfolio/SliderForPortfolio";
import AppBadgeWrapper from "../../../pageComponents/AppBadge/AppBadgeWrapper";
import AppBadge from "../../../pageComponents/AppBadge/AppBadge";

import "./portfolio-delivery.scss"

import firstScreen from "../../../../img/image/delivery/first-screen.png"
import secondScreen from "../../../../img/image/delivery/second-screen.png"
import threeScreen from "../../../../img/image/delivery/three-screen.png"
import fourthScreen from "../../../../img/image/delivery/fourth-screen.png"
import fifthScreen from "../../../../img/image/delivery/fifth-screen.png"

import delivery_1_en from "../../../../img/image/delivery/delivery_1_en.png";
import delivery_2_en from "../../../../img/image/delivery/delivery_2_en.png";
import delivery_3_en from "../../../../img/image/delivery/delivery_3_en.png";
import delivery_4_en from "../../../../img/image/delivery/delivery_4_en.png";
import delivery_5_en from "../../../../img/image/delivery/delivery_5_en.png";


const slidersList = {
    en: [delivery_1_en, delivery_2_en, delivery_3_en, delivery_4_en, delivery_5_en],
    ua: [firstScreen, secondScreen, threeScreen, fourthScreen, fifthScreen],
    text: ["first_slide", "second_slide", "three_slide", "fourth_slide", "fifth_slide"]
}


function PortfolioDelivery() {
    const {t, i18n} = useTranslation()

    const slides = useMemo(() => {
        const lng = (i18n.language === 'en' || i18n.language === 'es') ? 'en' : 'ua'

        return Array.isArray(slidersList[lng]) ? slidersList[lng].map((item, index) => {
            return {
                img: (<img src={item} className="phone" alt="Kavapp Delivery"/>),
                text: (
                    <p className={'description'}>
                        {t(`pages.portfolio.portfolio_delivery.description_slides.${slidersList.text[index]}`)}
                    </p>)
            }
        }) : []
    }, [i18n.language])

    return (
        <div className="delivery">
            <h1 className="page-title">Kavapp Delivery</h1>
            <h3 className="subtitle">{t("components.programForLogisticians")}</h3>
            <p className="description-delivery-page">{t("pages.portfolio.portfolio_delivery.description")}</p>
            <p className="sub-description">{t("pages.portfolio.portfolio_delivery.post_description")}</p>
            <p className={"minimum-characteristics"}>
                <span>{t("components.system_requirements.android")}</span><br/>
                <span>{t("components.system_requirements.ios")}</span><br/>
            </p>

            <AppBadgeWrapper>
                <AppBadge href="https://play.google.com/store/apps/details?id=com.kavapp.delivery" badge="google"/>
                <AppBadge href="https://apps.apple.com/us/app/id1622974235" badge="apple"/>
            </AppBadgeWrapper>
            <SliderForPortfolio slides={slides}/>
        </div>
    )
}

export default PortfolioDelivery
