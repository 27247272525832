export const pages = {
    about_us: {
        banner: "¡Asistente personal para el propietario de la cafetería!",
        description_first: "Kavapp – un sistema multifuncional y fácil de usar para la automatización de cafeterías, mini-cafeterías y auto-cafeterías.",
        description_second: "Nuestro sistema le brinda la posibilidad de configurar y organizar los procesos de trabajo en su negocio a un precio accesible.",
        subtitle: "¡Kavapp – fácil, accesible y rentable!",
        advantages: {
            low_price: "Precio bajo en comparación con otros servicios. Posibilidad de elegir la tarifa óptima para su tipo de establecimiento",
            low_price_mini: "Precio bajo",
            pppo: "Trabajo con pRRO - abra turnos fiscales y fiscalice los recibos vendidos en la autoridad fiscal directamente desde el dispositivo de trabajo, de acuerdo con la legislación vigente",
            pppo_mini: "Soporte pRRO",
            tech_card: "Creación de fichas técnicas en cantidad ilimitada",
            tech_card_mini: "Creación de fichas técnicas",
            wage: "Cálculo de salarios, asignación de multas, primas y anticipos",
            wage_mini: "Cálculo de salarios",
            control: "Control del trabajo del barista, logista y movimiento de mercancías en el almacén",
            control_mini: "Control del funcionamiento de la cafetería",
            rediscount: "Posibilidad de realizar inventarios en puntos de venta y en el almacén",
            rediscount_mini: "Función de inventario",
            sale_from_warehouse: "Función de venta de mercancías desde el almacén",
            sale_from_warehouse_mini: "Venta desde el almacén",
            charts: "Gran cantidad de gráficos e informes para controlar su negocio",
            charts_mini: "Gran cantidad de gráficos",
            messenger: "Messenger - comunicación interna entre baristas, logistas y administradores durante el horario laboral, sin distraerse del proceso de trabajo",
            messenger_mini: "Messenger",
            cooking: "Preparación previa de platos/posiciones del menú/ingredientes según la ficha técnica, para ventas posteriores o uso en el punto de venta",
            cooking_mini: "Preparación de platos según ficha técnica",
            device: "Alta compatibilidad del programa con diferentes dispositivos - el sistema Kavapp soporta dispositivos en Android, iOS y Windows",
            device_mini: "Funciona en Android, iOS, Windows",
        },
        categories: {
            title: "El sistema se divide en 3 espacios de trabajo",
            admin_title: "- Cuenta personal (Admin)",
            admin_description: " - espacio de trabajo del administrador",
            seller_title: "- Programa para ventas (Seller)",
            seller_description: " - espacio de trabajo del barista",
            delivery_title: "- Programa logístico, almacén y entrega (Delivery)",
            delivery_description: " - espacio de trabajo del logista",
        },
        head: {
            title: 'Kavapp – sistema para la automatización de cafeterías. Sistema CRM para cafeterías',
            description: 'Automatización de cafeterías con Kavapp. Sistema para la automatización de cafeterías, mini-cafeterías y auto-cafeterías. Conecte el sistema de automatización de cafeterías en línea'
        }
    },
    portfolio: {
        portfolio_main: {
            seller: "Programa para baristas",
            delivery: "Programa para logistas",
            admin: "Programa para administradores",
            head: {
                title: 'Elija CRM para la automatización de cafeterías. Kavapp - automatice su cafetería',
                description: 'Implementación del sistema CRM para cafeterías. Cómo elegir un sistema para la automatización de cafeterías. Conexión del programa para la automatización de cafeterías. Soporte de pRRO'
            },
        },
        portfolio_delivery: {
            description: "Kavapp Delivery - programa creado especialmente para facilitar y controlar el trabajo de su logista. Kavapp Delivery está diseñado para controlar el movimiento de mercancías desde el almacén hasta la cafetería, así como todo lo relacionado con la logística del almacén. En conjunto con todo el sistema, el logista ingresa la mercancía al almacén; desde el almacén, mediante el programa, el empleado registra la cantidad de mercancía entregada a la cafetería, sabe cuánto producto se utilizó para preparar bebidas y, en consecuencia, puede ver la cantidad de producto que queda en la cafetería en tiempo real a través del programa Kavapp Delivery (funcionalidad similar está disponible en el espacio de trabajo del administrador).",
            post_description: "Kavapp Delivery - la logística de su negocio.",
            description_slides: {
                first_slide: "Página principal de Delivery - en esta página el logista puede utilizar el messenger, recibir pedidos de los baristas, confirmar cancelaciones/incautaciones y compras en los puntos de venta.",
                second_slide: 'Menú "Almacén" - todo lo relacionado con la logística del almacén - disponibilidad, inventarios, ingresos e incluso ventas desde el almacén. Existe la posibilidad de generar informes sobre el movimiento de mercancías.',
                three_slide: "Pantalla de formación de pedidos - forme y envíe pedidos cómodamente utilizando la aplicación Kavapp Delivery",
                fourth_slide: 'Menú "Puntos de venta" - aquí el logista puede verificar la disponibilidad de mercancías en los puntos de venta, realizar inventarios en el punto y ver el movimiento de mercancías',
                fifth_slide: "Pantalla de configuración de Delivery - todas las configuraciones relacionadas con la aplicación en un solo lugar. Cambio rápido del tema de la aplicación a su gusto, configuración de seguridad, gestión de notificaciones y cambio del perfil del empleado.",
            },
            head: {
                title: 'Programa de control de existencias en cafeterías. Controle la logística con Kavapp',
                description: 'Kavapp Delivery - programa y espacio de trabajo del logista. Control fácil de existencias y movimiento de mercancías en cafeterías. Facilite el trabajo del logista con Kavapp'
            }
        },
        portfolio_seller: {
            description: "Kavapp Seller - parte del sistema Kavapp, a través de la cual se realizan directamente todas las ventas de su cafetería. Ventas convenientes y rápidas, posibilidad de crear pedidos de mercancías, incautación de efectivo, compras de mercancías en el punto, cualquier cancelación y muchas otras funciones que serán útiles para su empleado. Controle todas las ventas y el tiempo de trabajo del barista gracias a la aplicación Kavapp Seller.",
            post_description: "Kavapp Seller - conveniente, rápido y confiable.",
            link_apk: {
                description_one: "Descarga alternativa en Android ",
                description_two: ", en Windows ",
                link: "aquí",
            },
            description_slides: {
                first_slide: "Pantalla principal - el lugar de trabajo principal del barista en Seller. En esta pantalla, el barista selecciona las posiciones que deben agregarse al recibo. Si es necesario, el barista puede utilizar la búsqueda para encontrar rápidamente el producto deseado y posponer el recibo (manteniendo presionado el botón de vender durante 2 segundos)",
                second_slide: "Pantalla de cálculo. En esta pantalla, el barista indica el método de pago, si es necesario aplica un descuento y calcula el cambio que debe dar al cliente.",
                three_slide: "Pantalla de funciones - en esta pantalla, el barista puede crear pedidos/compras/cancelaciones e incautaciones en el punto de venta. También, el empleado puede ver la disponibilidad de mercancías en el punto de venta, registrar una tarjeta de bonificación para el cliente y ver todos los informes financieros por turno de trabajo.",
                fourth_slide: "Pantalla de recibos vendidos - aquí el barista puede ver todo el historial de recibos vendidos durante el turno de trabajo actual, crear un recibo de devolución o cancelar el último recibo vendido.",
                fifth_slide: "Pantalla de configuración - en este menú puede ver y cambiar todas las configuraciones relacionadas con la aplicación Seller, sincronizar datos con el panel de administración y conectar una impresora de recibos.",
            },
            head: {
                title: 'Programa conveniente para la automatización de cafeterías. Ventas convenientes y rápidas',
                description: 'Kavapp Seller - programa y espacio de trabajo para baristas. Interfaz intuitiva para empleados, fácil de aprender y usar'
            }
        },
        portfolio_admin: {
            description: 'Kavapp Admin - la parte principal del servicio Kavapp. En este espacio de trabajo realiza todas las configuraciones necesarias del sistema y puntos de venta, crea menús, precios, fichas técnicas. Una gran cantidad de gráficos e informes le ayudarán a analizar rápida y precisamente su negocio. En Admin también agrega a todos sus empleados, configura el sistema de descuentos, calcula salarios y, si es necesario, confirma pedidos/cancelaciones/incautaciones, etc.',
            post_description: 'Kavapp Admin - una poderosa herramienta en manos del administrador',
            description_slides: {
                first_slide: 'Página principal del gabinete personal - estadísticas breves sobre los turnos de trabajo actuales, información general y gráficos de ventas en sus puntos de venta.',
                second_slide: 'Menú "Puntos de venta" - cree sus puntos de venta, configure menús, precios, salarios y funcionalidad en cada punto.',
                three_slide: 'Menú "Empleados" - en esta pantalla agrega al personal que trabaja en sus puntos de venta a través de la aplicación Kavapp Seller e indica sus salarios - puede establecer diferentes salarios para cada empleado por separado.',
                fourth_slide: 'Menú "Logistas" - pantalla en la que agrega a sus logistas que trabajarán en la aplicación Kavapp Delivery (aquí puede obtener el código de conexión del logista).',
                fifth_slide: 'Menú "Ingredientes" - pantalla en la que crea todos los ingredientes a partir de los cuales luego se forman las fichas técnicas de sus bebidas y productos.',
                sixth_slide: 'Menú "Bebidas" - este es el menú de todas las bebidas que se venden en su punto de venta. Aquí selecciona el vaso que se utiliza para la preparación, indica el precio de la bebida y los ingredientes que la componen.',
                seventh_slide: 'Menú "Productos" - pantalla en la que crea todos los productos que se venderán en sus puntos de venta (estas posiciones consisten en ingredientes que se descuentan al vender).',
                eighth_slide: 'Menú "Mercancías" - son los productos que se venden en sus puntos de venta, el producto puede ser tanto unitario como por peso (estas posiciones no consisten en ingredientes).',
                ninth_slide: 'Menú "Inventario" - pantalla en la que puede realizar un inventario en el punto de venta, indicando los indicadores reales de disponibilidad de productos, ingredientes y vasos.',
                tenth_slide: 'Menú "Descuentos" - pantalla en la que crea todos los tipos de descuentos que pueden usarse para las tarjetas personales de sus clientes habituales.',
            },
            head: {
                title: 'Espacio de trabajo del administrador de la cafetería. CRM para la automatización de cafeterías',
                description: 'Kavapp Admin - espacio de trabajo del administrador. Automatización de la gestión de la cafetería: fichas técnicas, menús, cálculo de salarios, existencias'
            }
        },
    },

    tariffs: {
        banner: {
            main_title: {
                strong: "Los precios más favorables ",
                light: "del sistema de programas de gestión de cafeterías",
            },
            secondary_title: {
                strong: "Desde",
                currency: "UAH/mes",
                currency_usd: "USD/mes",
                for_shop: "por cafetería"
            },
        },

        card_title_currency: {
            uk: "UAH/mes",
            usd: "USD/mes",
        },
        card_title_every_point: "por punto de venta",
        button: {
            open_button: "Más detalles",
            close_button: "Cerrar",
        },
        priceSelect: "Precio al pagar por: ",
        period: {
            "1": "1 mes",
            "3": "3 meses",
            "6": "6 meses",
            "12": "12 meses",
        },
        head: {
            title: 'Tarifas del sistema de automatización para cafeterías Kavapp',
            description: 'Precios de CRM para la automatización de cafeterías. Programa económico para la automatización de cafeterías. Los precios más favorables del sistema de programas de gestión de cafeterías'
        }
    },
    contact: {
        left_block: {
            title: "Contáctenos",
            main_description: "Si tiene preguntas urgentes para nosotros o problemas al trabajar con el programa, puede contactarnos de la manera que le sea conveniente.",
            phone: "Teléfono:",
            email: "Correo electrónico:",
            work_schedule: "Horario de trabajo:",
        },
        right_block: {
            title: "Escribir una carta",
            name: "Nombre",
            mail: "Número de teléfono o correo electrónico",
            mail_usd: "Correo electrónico",
            mail_mini: "Teléfono o e-mail",
            mail_mini_usd: "E-mail",
            message: "Su pregunta o mensaje",
            error_message: "Datos no especificados",
            error_contact: "Datos no válidos",
            sent_message: "¡Su mensaje ha sido enviado!",
            no_message_sent: "¡Su mensaje no fue enviado! ¡Intente enviar de nuevo!",
        },
        head: {
            title: 'CRM Kavapp - contáctenos para la automatización de cafeterías',
            description: 'Conecte el programa Kavapp - contáctenos para el cálculo y conexión. La automatización de cafeterías es fácil y económica con Kavapp'
        }
    },
}

export default pages

export const tariff_list = [
    "Cantidad ilimitada de fichas técnicas",
    "Salarios, anticipos, multas",
    "Almacén y logística",
    "Configuraciones de seguridad",
    "Messenger integrado",
    "Preparación previa",
    "Sistema de descuentos",
    "Constructor de promociones",
    "Cálculos de costos",
    "Modificadores de bebidas",
    "Venta desde almacén",
    "Diferentes precios en cafeterías"
]

export const add_tariff_list = [
    "Informes básicos",
    "Informes completos",
    "pRRO",
]