import React, {useContext, useEffect, useState} from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {Trans, useTranslation} from "react-i18next";
import i18n from 'i18next';
import {IconButton, InputAdornment, TextField} from "@mui/material";

import List from "./components/List";

import {textList} from "./components/textList";
import {FaqListContext} from "./context/context";
import {useDebounce} from "../../../hooks/useDebounce";
import './FaqList.scss'
import {Email, KavAppLink, PrivacyPolicy, SellerLink, UserAgreement} from "./components/i18Components";
import {GlobalContext} from "../../../app/providers/ContextProvider";

const i18Components = {
    kavAppLink: <KavAppLink/>,
    sellerLink: <SellerLink/>,
    userAgreement: <UserAgreement/>,
    privacyPolicy: <PrivacyPolicy/>,
    email: <Email/>
}


// Формуємо масив з даними для рендера (номера пунктів списку і відповідний текст )
function getListText(list, firstNumber = '', parent) {
    let number = ''
    list.forEach((item, index) => {
        if (item.name) {
            item.name = i18n.t(`faq.${item.name}`)
            item.number = firstNumber ? `${firstNumber}.${index + 1}.` : `${index + 1}.`
            number = firstNumber ? `${firstNumber}.${index + 1}` : `${index + 1}`
        }

        if (item.text) {
            item.parent = parent.number
            if (Array.isArray(item.components) && item.components.length > 0) {
                item.text = <Trans
                    i18nKey={`faq.${item.text}`}
                    components={item.components.map(item => {
                        if(typeof item === 'string' && i18Components[item]) return i18Components[item]
                        return false
                    })}
                />
            } else item.text = i18n.t(`faq.${item.text}`)
        }
        if (item.children) getListText(item.children, number, item)
    })
    return list
}

// Формування списку пунктів меню і його предків
function updateOpenItems(parentId, openItems) {
    openItems[parentId] = true
    const index = parentId.lastIndexOf('.', parentId.length - 2)
    if (index > 0) {
        const string = parentId.slice(0, index + 1)
        updateOpenItems(string, openItems)
    }
}

// Пошук текста у пунктах списка і при співподінні відмічаємо їх для послідуючого розгортання списку.
function findText(list, searchText, openItems) {
    list.forEach(item => {
        if ((item.name && item.number) && item.name.toLowerCase().includes(searchText.toLowerCase())) updateOpenItems(item.number, openItems)
        if ((item.text && item.parent && !item.components) && item.text.toLowerCase().includes(searchText.toLowerCase())) updateOpenItems(item.parent, openItems)
        if (item.children) findText(item.children, searchText, openItems)
    })

}

function showFoundItems(list, searchText, cb) {
    const openItems = {}
    if (searchText.trim().length !== 0) {
        if (searchText.length > 2) {
            findText(list, searchText, openItems)
            cb(openItems)
        }
    } else {
        cb({})
    }
}

// Фільтрування по ПРРО та оновлення textList якщо зиінилася країна
function filterTextList(country) {
    let temp = []
    if (country !== 'UA') {
        temp = textList.filter(item => item.type !== 'prro')
    } else temp = textList

    return JSON.parse(JSON.stringify(temp))
}

const FaqList = () => {
    const {t} = useTranslation()
    const {globalState: {country}} = useContext(GlobalContext)
    // Об'єкт для зберігання стану розгорнутих пунктів меню {id: boolean}
    const [isOpenItemsInList, setIsOpenItemsInList] = useState({})
    const [searchText, setSearchText] = useState('')
    const listWithText = filterTextList(country)

    const debouncedFindText = useDebounce(showFoundItems, 500)

    useEffect(() => {
        debouncedFindText(listWithText, searchText, setIsOpenItemsInList)
    }, [searchText])


    // Розгортання списку при натисканні на елементі списку
    const onClickOnListItem = item => {
        if (item.children) {
            setIsOpenItemsInList({...isOpenItemsInList, [item.number]: !isOpenItemsInList[item.number]})
        }
    }

    const clearSearch = () => setSearchText('')


    return (
        <div className="faq">
            <FaqListContext.Provider value={{onClickOnListItem, isOpenItemsInList, searchText}}>
                <div className={'faq-title-wrp'}>
                    <div className={'faq-tittle'}>{t('faq.title')}</div>

                    <div className='text-field-wrp'>
                        <TextField variant={'outlined'}
                                   value={searchText}
                                   onChange={event => setSearchText(event.target.value)}
                                   label={t('faq.search')}
                                   className={'text-field'}
                                   InputProps={{
                                       endAdornment:
                                           <InputAdornment position="end">
                                               <IconButton
                                                   className="clear-search-button"
                                                   onClick={clearSearch}
                                                   disabled={!searchText}
                                               >
                                                   <ClearIcon color="action"/>
                                               </IconButton>
                                           </InputAdornment>
                                   }}
                        />
                    </div>
                </div>


                <List list={getListText(listWithText)}/>
            </FaqListContext.Provider>
        </div>
    );
};

export default FaqList;
