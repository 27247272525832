import React from 'react';


import FaqListItem from "./FaqListItem";
import {List} from "@mui/material";

export const ListWrapper = (props) => {
  const {list, style} = props

  return (
    <List className='list-faq' style={style}>
      {list.map(item =>
        <FaqListItem
          key={item.number ? item.number : item.text}
          item={item}
        />
      )}
    </List>
  );
};

export default ListWrapper;
