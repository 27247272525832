import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import "./slider_for_portfolio.scss"

function SliderForPortfolio({slides}) {
    const {i18n} = useTranslation();
    const language = i18n.language
    const [slideData, setSlideData] = useState(slides[0])
    const [count, setCount] = useState(0)

    function onPrevButtonClick() {
        if (count === 0) {
            setSlideData(slides[slides.length - 1])
            setCount(slides.length - 1)
            return
        }

        setSlideData(slides[count - 1])
        setCount(count - 1)
    }

    function onNextButtonClick() {
        if (count === slides.length - 1) {
            setSlideData(slides[0])
            setCount(0)
            return
        }

        setSlideData(slides[count + 1])
        setCount(count + 1)
    }

    useEffect(() => {
        setSlideData(slides[count])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language])


    return (
        <div className="slider-for-portfolio">
            <div onClick={onPrevButtonClick} className="left-button">&#10094;</div>
            <div className="slide">
                {slideData.img}
                {slideData.text}
            </div>
            <div onClick={onNextButtonClick} className="right-button">&#10095;</div>
        </div>
    )
}

export default SliderForPortfolio