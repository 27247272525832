const text = {
    search: "Search",
    title: "FAQ (Questions and answers)",
    link: "link"
}

const list1 = {
    "1": "Connection, necessary equipment, getting started.",
    "1_1": "How to connect the Kavapp system?",
    "1_1t": "To work with our system, you just need to register on our website <0>Kavapp.com</0> and download the <1>Kavapp Seller</1> application to your device. You can make all the system settings yourself or with the help of our technical support.",

    "1_2": "What hardware does Kavapp work with?",
    "1_2t1": "Kavapp Admin works as a website and can be accessed from any browser on any device.",
    "1_2t2": "Kavapp Seller works as a mobile application on Windows (10+, 64-bit), Android (minimum 6, recommended 9+) and iOS (minimum 12, recommended 15+).",
    "1_2t3": "Kavapp Delivery works as a mobile application on Android (minimum 6, recommended 9+) and iOS (minimum 12, recommended 15+).",
    "1_2t4": "The Kavapp Seller app supports the connection of receipt printers (Bluetooth, USB, Wi-Fi) and barcode scanners (Bluetooth, USB).",

    "1_3": "Is the system ready to work after registration?",
    "1_3t": "Yes, immediately after registration, you get a full version of the system with a trial period of 2 weeks free of charge on any tariff. Standard products and technical cards are immediately available in the software, which you can edit and customise during the trial period and after it is over. All the settings you make during the trial period will be saved after it ends.  ",
    "1_4": "Will Kavapp employees set up our system or install the equipment at the coffee shop?",
    "1_4t": "No, we work only online and do not connect the equipment in your coffee shop, but if you need help connecting or setting up the system, you can contact our technical support, we will be happy to help.",
    "1_5": "Do I need the Internet to work with the program?",
    "1_5t": "The Internet is required to open and close a work shift in the Kavapp Seller app, if the shift has already started, the app can work offline (in this case, the information in the personal account will not be synchronised and will be synchronised when the device is connected to the Internet).",
    "1_6": "Can I buy equipment from Kavapp?",
    "1_6t": "At the moment, we do not sell any equipment, but you can get recommendations for specific equipment from our technical support.",
}

const list2 = {
    "2": "About Kavapp.",
    "2_1": "How long has the Kavapp service been operating?",
    "2_1t": "Our system has been in operation since 2019, we are constantly working to improve and expand our service and have no plans to stop in the future.",
    "2_2": "How often is the system updated and improved?",
    "2_2t": "Global application updates are released on a monthly basis, while we may release smaller updates much more frequently, all of which is necessary to ensure stable system operation and improve functionality. We try to listen to our customers to provide the most comfortable and efficient services possible.",

    "2_3": "What are the main advantages of Kavapp over other POS systems for coffee shops?",
    "2_3t1": "We provide effective and simple functionality for our customers based on our extensive experience in this area of business, so our system is maximally focused on working with coffee shops. We have worked through many of the pitfalls that occur in this business and provide turnkey solutions that will help simplify, speed up and improve the operation of your coffee shop.",
    "2_3t2": "We also consider our advantage to be the low prices for our services compared to our competitors, which are currently among the lowest on the market, and this is due to the fact that we strive to give our customers the maximum for the minimum - this is our company's policy.",
    "2_3t3": "Another important difference from most other services is the absence of any restrictions on the volume of the system: only in the Kavapp system you can create an unlimited number of goods, personnel and technical cards at no additional cost.",

    "2_4": "Kavapp user agreement and privacy policy.",
    "2_4t1": "You can read the user agreement that you sign when registering on our website by following the <0>?????</0>.",
    "2_4t2": "You can read the Kavapp privacy policy by following the <0>?????</0>. ",

    "2_5": "Is it possible to cooperate with Kavapp?",
    "2_5t1": "Yes, but each proposal is considered individually by the management, if you have a proposal for cooperation, please describe it and send it to our email address <0>info@kavapp.com</0>.",
    "2_5t2": "We will quickly process your proposal and provide a response as soon as possible.",
}

const list3 = {
    "3": "Payment and tariffs.",
    "3_1": "How is the system paid for?",
    "3_1t": "After your trial period expires, you choose your tariff and select the period of use you want to pay for (the cost is calculated based on the chosen tariff and the number of points of sale) and make a payment via the LiqPay service directly on the page of your personal account. ",

    "3_2": "How do the tariffs differ? ",
    "3_2t1": "Our tariff plans differ only in the specific functionality included in each of them. ",
    "3_2t2": "The S tariff is a basic functionality with all the reporting, but does not include the ability to calculate the cost of items. ",
    "3_2t3": "Tariff M includes the functionality of the S tariff plus financial calculations (costing and payment for purchases with cash), a discount system and a promotions designer.",
    "3_2t4": "The L tariff includes the functionality of the S and M tariffs plus a menu item designer (drink options and modifiers), the ability to sell from the warehouse and set different menus and prices at different outlets.",

    "3_3": "Can Kavapp issue a separate invoice for payment?",
    "3_3t": "No, payment is made only directly through the LiqPay service on the page of your Kavapp personal account.",
    "3_4": "What types of payment are accepted?",
    "3_4t": "You can make a payment from any card that the LiqPay service works with, including the most common Visa and MasterCard.",
}

const list4 = {
    "4": "Software registrar of payment transactions",
    "4_1": "What tariffs include a cash register and how to use it?",
    "4_1t": "The software registrar of payment transactions is included in all Kavapp plans at no additional cost. You will receive step-by-step instructions on how to set up and connect in your personal cabinet after registering on our website."
}


export const faq = {
    ...text,
    ...list1,
    ...list2,
    ...list3,
    ...list4
}